import React, { useContext } from 'react';
import type { ComponentProps } from 'react';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';
import { InfoWindow as GoogleInfoWindow } from '@react-google-maps/api';

import {
  LABEL_HIDE_INTEREST_POINTS,
  LABEL_INTEREST_POINTS,
  LABEL_ROUTE,
  LABEL_VI4YOU,
} from 'settings/labels';

import type { ProgramListType } from 'api/viOffresAPI/apiTypes/Program';

import { getMapDirectionProps } from 'services/map';
import { programIsControlledPrice, programIsVi4You } from 'services/programs';
import { taxMapping } from 'services/taxes';
import { modifyQuery } from 'services/url';

import ResponsiveContext from 'modules/App/Contexts/ResponsiveContext';
import TaxonomiesContext from 'modules/App/Contexts/TaxonomiesContext';
import PastilleFiscality from 'commonUi/Pastille/PastilleFiscality';

import Button from 'commonUi/Button/Button';

import Icon from 'sharedModulesV4/common/components/Atoms/Icon';
import SvgIcon from 'commonUi/SvgIcon/SvgIcon';

import ProgramCardThumbnailLabel from '../../../ProgramCard/ProgramCardThumbnailLabel';

import styles from './InfoBox.module.scss';

interface InfoBoxProps {
  isOpen?: boolean;
  onClose?: ComponentProps<typeof GoogleInfoWindow>['onCloseClick'];
  position?: ComponentProps<typeof GoogleInfoWindow>['position'];
  program: ProgramListType;
  displayKelQuartier?: boolean;
  displayKelQuartierToggle?: boolean;
  toggleKelQuartier?: () => void;
}

export default function InfoBox({
  isOpen = false,
  onClose,
  position,
  program,
  displayKelQuartier = false,
  displayKelQuartierToggle = false,
  toggleKelQuartier = () => {},
}: InfoBoxProps) {
  const history = useHistory();
  const { isResponsive, isAppleDevice } = useContext(ResponsiveContext);
  const { taxesById } = useContext(TaxonomiesContext);

  function showItineraire() {
    const url = getMapDirectionProps(program.lat, program.lng, null, !isResponsive, isAppleDevice);
    window.open(url.href, '_blank');
  }

  function handleCloseCard() {
    if (isResponsive && displayKelQuartier) {
      toggleKelQuartier();
    }
    history.replace(modifyQuery({}, ['programRef']));
  }

  if (!isOpen) {
    return null;
  }

  const currentFiscality = taxesById?.[program.taxes[0]]
    ? taxMapping.get(taxesById[program.taxes[0]])
    : undefined;

  const innerHtml = (
    <>
      {isResponsive && (
        <button className={styles.closeCardBtn} onClick={handleCloseCard} type="button">
          <Icon icon="cross" />
        </button>
      )}
      <div className={styles.root}>
        <div className={styles.content}>
          <a
            className={styles.programInfos}
            href={`/programme/page/${program.ref}/prix`}
            target="_blank"
            rel="noreferrer"
          >
            <div className={styles.image}>
              <img src={program.image?.[0]} alt="" />
              <ProgramCardThumbnailLabel className={styles.thumbnailLabel} program={program} />
              <div className={styles.pictos}>
                {programIsVi4You(program) && (
                  <div className={styles.pictosItem}>
                    <img
                      className={styles.vi4you}
                      src="/images/Logo_4you_Plan_de_travail_1.png"
                      title={LABEL_VI4YOU}
                      alt={LABEL_VI4YOU}
                    />
                  </div>
                )}
                {programIsControlledPrice(program) && (
                  <div className={styles.pictosItem}>
                    <SvgIcon iconId="icon-prix-maitrises" />
                  </div>
                )}
              </div>
            </div>
            <div className={styles.programContent}>
              <div className={styles.name}>{program.name}</div>
              <div className={styles.infos}>
                {currentFiscality && (
                  <PastilleFiscality className={styles.pastille} tax={currentFiscality} />
                )}
                <div className={styles.location}>
                  {program.city} ({program.postalCode})
                </div>
              </div>
            </div>
          </a>
          <div className={styles.buttons}>
            {displayKelQuartierToggle && (
              <div className={styles.buttonWrapper}>
                <Button
                  className={classnames(styles.button, {
                    [styles.kelQuartierOpen]: displayKelQuartier,
                  })}
                  color="secondary"
                  fullWidth
                  labelClassName={styles.buttonLabel}
                  onClick={toggleKelQuartier}
                  variant="contained"
                >
                  {!displayKelQuartier && (
                    <>
                      <SvgIcon
                        iconId="icon-point-interest"
                        className={classnames(styles.iconSvg, styles.iconDefault)}
                      />
                      <SvgIcon
                        iconId="icon-point-interest-hover"
                        className={classnames(styles.iconSvg, styles.iconHover)}
                      />
                    </>
                  )}
                  <span className={styles.label}>
                    {displayKelQuartier ? LABEL_HIDE_INTEREST_POINTS : LABEL_INTEREST_POINTS}
                  </span>
                </Button>
              </div>
            )}
            <div className={styles.buttonWrapper}>
              <div>
                <Button
                  className={styles.button}
                  color="secondary"
                  fullWidth
                  labelClassName={styles.buttonLabel}
                  onClick={showItineraire}
                  variant="contained"
                >
                  <SvgIcon
                    iconId="icon-route"
                    className={classnames(styles.iconSvg, styles.iconDefault)}
                  />
                  <SvgIcon
                    iconId="icon-route-hover"
                    className={classnames(styles.iconSvg, styles.iconHover)}
                  />
                  <span className={styles.label}>{LABEL_ROUTE}</span>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  if (isResponsive) {
    return innerHtml;
  }

  return (
    <GoogleInfoWindow
      onCloseClick={onClose}
      options={{ pixelOffset: new window.google.maps.Size(-10, -60) }}
      position={position}
    >
      {innerHtml}
    </GoogleInfoWindow>
  );
}
