import React, { useContext } from 'react';
import classnames from 'classnames';

import type { Activity } from 'api/vi3pAPI/apiTypes/ActivityType';
import type { LotTypeV2 } from 'api/viOffresAPI/apiTypes/LotType';
import type { ProgramTypeV2 } from 'api/viOffresAPI/apiTypes/Program';

import TaxonomiesContext from 'modules/App/Contexts/TaxonomiesContext';

import ActivityDetailsAdditional from './ActivityDetailsAdditional';
import ActivityDetailsFinancial from './ActivityDetailsFinancial';
import ActivityDetailsHousing from './ActivityDetailsHousing';
import ActivityDetailsInformationsGeneral from './ActivityDetailsInformationsGeneral';

import styles from './ActivityDetails.module.scss';

interface ActivityDetailsProps {
  activity?: Activity;
  isOdd?: boolean;
  lotFromApi?: LotTypeV2;
  programFromApi?: ProgramTypeV2;
}

export function ActivityDetails({
  activity,
  isOdd = false,
  lotFromApi,
  programFromApi,
}: ActivityDetailsProps) {
  const { taxes: fiscalities } = useContext(TaxonomiesContext);
  const fiscality = fiscalities?.find(fiscality => fiscality.nom === lotFromApi?.fiscalites[0]);

  if (!programFromApi || !lotFromApi) return null;

  return (
    <div
      className={classnames(styles.root, {
        [styles.odd]: isOdd,
        [styles.displayActivity]: activity !== undefined,
      })}
    >
      <div className={classnames(styles.col, styles.colInfos)}>
        <ActivityDetailsInformationsGeneral
          classes={{ root: styles.infos, title: styles.infosTitle }}
          fiscality={fiscality}
          lot={lotFromApi}
          program={programFromApi}
        />
      </div>

      <div className={classnames(styles.col, styles.colHousingInfos)}>
        <ActivityDetailsHousing
          classes={{ root: styles.infos, title: styles.infosTitle }}
          lot={lotFromApi}
        />
      </div>

      <div className={classnames(styles.col, styles.colAdditionalInfos)}>
        <ActivityDetailsAdditional
          classes={{ root: styles.infos, title: styles.infosTitle }}
          lot={lotFromApi}
        />
      </div>
      <div className={classnames(styles.col, styles.colFinancialInfos)}>
        <ActivityDetailsFinancial
          classes={{ root: styles.infos, title: styles.infosTitle }}
          fiscality={fiscality}
          lot={lotFromApi}
          program={programFromApi}
        />
      </div>
    </div>
  );
}
