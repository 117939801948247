import React from 'react';
import type { ComponentProps } from 'react';

import useLoadProgramAndLot from 'hooks/useLoadProgramAndLot';

import { ActivityDetails } from 'commonUi/ListLotV2/ActivityRow/ActivityDetails/ActivityDetails';
import LotDetailsImage from 'commonUi/ListLotV2/LotDetails/LotDetailsImage';
import LotDetailLayoutTablette from 'commonUi/ListLotV2/LotDetailLayout/LotDetailLayoutTablette';

interface ActivityTabletteDetailsCpnProps {
  activity: ComponentProps<typeof ActivityDetails>['activity'];
  closeDrawer: ComponentProps<typeof LotDetailLayoutTablette>['closeDrawer'];
  lot:
    | {
        reference: string;
        referenceProgramme: string;
      }
    | undefined;
}

export function ActivityTabletteDetailsCpn({
  activity,
  closeDrawer,
  lot,
}: ActivityTabletteDetailsCpnProps) {
  const { lot: lotDetail, program: programDetail } = useLoadProgramAndLot(
    lot?.referenceProgramme,
    lot?.reference
  );

  return (
    <LotDetailLayoutTablette
      closeDrawer={closeDrawer}
      loaded={lotDetail !== undefined && programDetail !== undefined}
      lotNumber={lot?.reference}
      renderDetails={() => {
        return (
          <ActivityDetails
            activity={activity}
            lotFromApi={lotDetail}
            programFromApi={programDetail}
          />
        );
      }}
      renderImage={() => {
        return <LotDetailsImage lotFromApi={lotDetail} programFromApi={programDetail} />;
      }}
    />
  );
}
