import React, { ReactNode, useContext, useMemo, useState } from 'react';
import type { ComponentProps } from 'react';
import classnames from 'classnames';

import { PROGRAM_ANCHORS_DOM_ID, PROGRAM_PROMO_DOM_ID } from 'settings/ui';

import { LABEL_SORT_BY } from 'settings/labels';
import { LOT_CELL_ID_KIND } from 'settings/lots';
import { SORT_ORDER_ASC, SORT_ORDER_DESC } from 'settings/search';

import type { LotJson, LotSortBy, LotSortOrder } from 'api/viOffresAPI/apiTypes/LotType';
import type { ProgramTypeV2 } from 'api/viOffresAPI/apiTypes/Program';

import { getLotHeadings, getLotsUniqueTaxType, sortLots } from 'services/lots';
import { programIsFurnishEligible } from 'services/programs';

import TaxonomiesContext from 'modules/App/Contexts/TaxonomiesContext';

import DropdownList from 'commonUi/DropdownList/DropdownList';
import SortItem from 'commonUi/SortItem/SortItem';
import LotTableMobile from './LotTableMobile';

import styles from './ProgramLotsMobile.module.scss';

interface ProgramLotsMobileProps {
  className?: string;
  headerContent?: ReactNode;
  lots: LotJson[];
  pageTemplate?: string;
  pageTemplateRef?: string;
  program: ProgramTypeV2;
  statuses: ComponentProps<typeof LotTableMobile>['statuses'];
  updateStatus: (lotNid: string, status: any) => void;
}

export default function ProgramLotsMobile({
  className = undefined,
  headerContent = undefined,
  lots = [],
  program,
  pageTemplate = undefined,
  pageTemplateRef = undefined,
  statuses,
  updateStatus,
}: ProgramLotsMobileProps) {
  const { taxesById } = useContext(TaxonomiesContext);
  const [sortBy, setSortBy] = useState<LotSortBy>(LOT_CELL_ID_KIND);
  const [sortOrder, setSortOrder] = useState<LotSortOrder>(SORT_ORDER_ASC);

  const sortedLots = useMemo<LotJson[]>(() => sortLots(lots, sortBy, sortOrder), [
    lots,
    sortBy,
    sortOrder,
  ]);

  const headings = useMemo(() => {
    const taxType = getLotsUniqueTaxType(lots, taxesById);
    return getLotHeadings(false, taxType, true, true, 'mobile', programIsFurnishEligible(program));
  }, [lots, taxesById, program]);

  if (!taxesById) {
    return null;
  }

  function reorderLots(type: LotSortBy) {
    if (type === sortBy) {
      if (sortOrder === SORT_ORDER_ASC) {
        setSortOrder(SORT_ORDER_DESC);
      } else {
        setSortOrder(SORT_ORDER_ASC);
      }
    } else {
      setSortBy(type);
      setSortOrder(SORT_ORDER_ASC);
    }
  }

  const programMenuAnchors = document.getElementById(PROGRAM_ANCHORS_DOM_ID);
  const programPromoBanner = document.getElementById(PROGRAM_PROMO_DOM_ID);

  return (
    <div className={classnames(className, styles.root)}>
      {headerContent && (
        <div
          className={styles.lotsListHeader}
          style={
            programPromoBanner
              ? {
                  top:
                    (programMenuAnchors?.offsetHeight || 0) +
                    (programPromoBanner?.offsetHeight || 0),
                }
              : undefined
          }
        >
          {headerContent}
        </div>
      )}
      <DropdownList id="sort-lots" className={styles.sorter} label={LABEL_SORT_BY}>
        {headings
          .filter(({ field }) => field)
          .map(({ field, title, id }) => (
            <SortItem
              key={id}
              onClick={v => reorderLots(v)}
              sortBy={sortBy}
              sortOrder={sortOrder}
              title={title}
              type={field as typeof sortBy} // entries with `undefined` `field` value have been filtered out
            />
          ))}
      </DropdownList>
      <LotTableMobile
        headings={headings}
        hideMapButton
        lots={sortedLots}
        pageTemplate={pageTemplate}
        pageTemplateRef={pageTemplateRef}
        statuses={statuses}
        updateStatus={updateStatus}
      />
    </div>
  );
}
