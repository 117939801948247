import React, { useContext, useEffect, useState } from 'react';
import type { ReactNode } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { LABEL_LOTS_ENABLE_ERROR_SUBTITLE, LABEL_LOTS_ENABLE_ERROR_TITLE } from 'settings/labels';
import { SEARCH_NB_ROWS_API_LOAD } from 'settings/search';
import { LOTS_PER_PAGE_MOBILE } from 'settings/lots';

import type { HeadingType, LotJson } from 'api/viOffresAPI/apiTypes/LotType';

import SettingsContext from 'modules/App/Contexts/SettingsContext';
import TaxonomiesContext from 'modules/App/Contexts/TaxonomiesContext';
import userContext from 'modules/App/Contexts/userContext';

import useGetLotsStatuses from 'hooks/useGetLotsStatuses';
import useLoadProgramAndLot from 'hooks/useLoadProgramAndLot';

import { useSearch } from 'modules/HomePage/hooks/useSearch';

import MobileDrawer from 'layout/components/HeaderAuthenticated/Drawer/MobileDrawer';
import LotTableContainer from 'commonUi/ListLotV2/LotTable/LotTableContainer';
import LotRowMobile from 'commonUi/ListLotV2/LotRow/LotRowMobile';
import LotTableErrorModal from 'commonUi/ListLotV2/LotTable/LotTableErrorModal';
import { LotDetailsMobileCpn } from 'modules/DetailsLotsAndActivity/LotDetailsMobileCpn';

interface LotTableMobileProps {
  activeProgramRef?: string;
  activeLots?: LotJson[];
  currentPage: number;
  handleOnChangeCheckbox?: (programRef: string, lotNumber: string) => void;
  headings?: HeadingType[];
  hideMapButton?: boolean;
  lots?: LotJson[];
  noResultMessage?: ReactNode;
  reduceMobilePanel?: () => void;
  withCheckbox?: boolean;
  loadMore: any;
  hasMore: any;
  pageTemplate?: string;
  pageTemplateRef?: string;
}

export default function LotTableMobile({
  activeProgramRef,
  activeLots = [],
  currentPage,
  handleOnChangeCheckbox = undefined,
  headings = [],
  hideMapButton = false,
  lots = [],
  noResultMessage = null,
  reduceMobilePanel = undefined,
  withCheckbox = false,
  loadMore = undefined,
  hasMore = false,
  pageTemplate = undefined,
  pageTemplateRef = undefined,
}: LotTableMobileProps) {
  const { settings } = useContext(SettingsContext);
  const isAttributionSwitchedOn = !!settings.cdo?.liste_attribution;
  const { options: myOptions, preBookings: myBookings, alerts, toggleAlert, userCrm } = useContext(
    userContext
  );
  const { lotNumber, programRef } = useSearch();

  const { statuses, updateStatus } = useGetLotsStatuses(
    lots.slice(
      Math.trunc((currentPage * LOTS_PER_PAGE_MOBILE) / SEARCH_NB_ROWS_API_LOAD) *
        SEARCH_NB_ROWS_API_LOAD,
      (Math.trunc((currentPage * LOTS_PER_PAGE_MOBILE) / SEARCH_NB_ROWS_API_LOAD) + 1) *
        SEARCH_NB_ROWS_API_LOAD
    ),
    // If attribution switch is ON, the lots have already been filtered for it in `useLot`
    isAttributionSwitchedOn ? undefined : userCrm?.extension_VI3P_ListeAttribution
  );
  const { taxes: fiscalities } = useContext(TaxonomiesContext);

  const [currentRowOpen, setCurrentRowOpen] = useState<string>();
  const [loadLotError, setLoadLotError] = useState<boolean>(
    typeof lotNumber !== 'undefined' &&
      typeof programRef !== 'undefined' &&
      lots.length > 0 &&
      !lots.some(lot => lot.number === lotNumber && lot.ref === programRef)
  );
  const [openError, setOpenError] = useState<boolean>(false);

  // load current lot
  const { number: currentLotNumber, ref: currentProgramRef } =
    lots?.find(lot => currentRowOpen === lot.nid) ?? {};
  const {
    lot: lotDetail,
    program: programDetail,
    isLoading: isDetailsLoading,
    isError,
  } = useLoadProgramAndLot(currentProgramRef, currentLotNumber);

  useEffect(() => {
    if (lotNumber) {
      const activeIndex = lots?.findIndex(l => l.number === lotNumber);
      const currentOpen = activeIndex !== -1 ? lots[activeIndex].nid : undefined;
      setCurrentRowOpen(currentOpen);
    }
  }, [lotNumber]);

  useEffect(() => {
    if (!isDetailsLoading && isError) {
      setOpenError(true);
    }
  }, [isDetailsLoading, isError]);

  if (!lots?.length && !activeLots?.length) return <div>{noResultMessage}</div>;

  // do use effect
  const heightRefElem = document.getElementById('panelContent');

  return (
    <>
      <LotTableErrorModal
        open={loadLotError || openError}
        onClose={() => {
          setOpenError(false);
          setLoadLotError(false);
        }}
        title={LABEL_LOTS_ENABLE_ERROR_TITLE}
        subtitle={LABEL_LOTS_ENABLE_ERROR_SUBTITLE}
      />

      <InfiniteScroll
        loader={<div />}
        dataLength={activeLots.length + lots.length}
        next={loadMore}
        hasMore={hasMore}
        style={{ overflowX: 'hidden', overflowY: 'scroll' }}
        hasChildren
        height={heightRefElem ? heightRefElem.clientHeight : undefined}
        scrollableTarget={window}
      >
        <LotTableContainer>
          {activeProgramRef &&
            activeLots
              ?.filter(lot => lot.ref === activeProgramRef)
              .map(lot => (
                <LotRowMobile
                  onStatusAlertClick={toggleAlert}
                  myBookings={myBookings}
                  myOptions={myOptions}
                  alerts={alerts}
                  headings={headings}
                  hideMapButton={hideMapButton}
                  key={lot.nid}
                  lot={lot}
                  statuses={statuses}
                  updateStatus={updateStatus}
                  reduceMobilePanel={reduceMobilePanel}
                  pageTemplate={pageTemplate}
                  pageTemplateRef={pageTemplateRef}
                  isDrawerOpen={currentRowOpen === lot.nid}
                  setCurrentRowOpen={setCurrentRowOpen}
                  renderShowMore={() => {
                    if (!lotDetail || !programDetail) {
                      return null;
                    }
                    return (
                      <MobileDrawer isDrawerOpen>
                        <LotDetailsMobileCpn
                          closeDrawer={() => setCurrentRowOpen(undefined)}
                          fiscalities={fiscalities}
                          lot={lot}
                          myBookings={myBookings}
                          myOptions={myOptions}
                          statuses={statuses}
                          updateStatus={updateStatus}
                          pageTemplate={pageTemplate}
                          pageTemplateRef={pageTemplateRef}
                          lotApiOffre={lotDetail}
                          programDatas={programDetail}
                        />
                      </MobileDrawer>
                    );
                  }}
                />
              ))}
          {lots?.map(lot => (
            <LotRowMobile
              onStatusAlertClick={toggleAlert}
              myBookings={myBookings}
              myOptions={myOptions}
              alerts={alerts}
              key={lot.nid}
              handleOnChangeCheckbox={handleOnChangeCheckbox}
              headings={headings}
              hideMapButton={hideMapButton}
              lot={lot}
              statuses={statuses}
              updateStatus={updateStatus}
              reduceMobilePanel={reduceMobilePanel}
              withCheckbox={withCheckbox}
              pageTemplate={pageTemplate}
              pageTemplateRef={pageTemplateRef}
              isDrawerOpen={currentRowOpen === lot.nid}
              setCurrentRowOpen={setCurrentRowOpen}
              renderShowMore={() => {
                if (!lotDetail || !programDetail) {
                  return null;
                }
                return (
                  <MobileDrawer isDrawerOpen>
                    <LotDetailsMobileCpn
                      closeDrawer={() => setCurrentRowOpen(undefined)}
                      fiscalities={fiscalities}
                      lot={lot}
                      myBookings={myBookings}
                      myOptions={myOptions}
                      statuses={statuses}
                      updateStatus={updateStatus}
                      pageTemplate={pageTemplate}
                      pageTemplateRef={pageTemplateRef}
                      lotApiOffre={lotDetail}
                      programDatas={programDetail}
                    />
                  </MobileDrawer>
                );
              }}
            />
          ))}
        </LotTableContainer>
      </InfiniteScroll>
    </>
  );
}
