import {
  TOKEN_CATEGORY,
  TOKEN_DATE,
  TOKEN_FOLDER_NAME,
  TOKEN_LIST,
  TOKEN_LOT_NUMBER,
  TOKEN_MAX,
  TOKEN_MAX_SIZE_IMG,
  TOKEN_MAX_SIZE_PDF,
  TOKEN_MIN,
  TOKEN_NAME,
  TOKEN_NB,
  TOKEN_NB_LOTS,
  TOKEN_NB_PROGRAMS,
  TOKEN_PRICE,
  TOKEN_PROGRAM_NAME,
  TOKEN_REF,
  TOKEN_TYPES,
  TOKEN_VENDOR,
  TOKEN_VERSION,
} from './token';
import { NON_BREAKING_SPACE } from './ui';

export const LABEL_3D_RESIDENCE_TITLE = 'La résidence en 3D';
export const LABEL_ACCESS_OFFER = 'Cliquez-ici pour accéder à notre offre.';
export const LABEL_ACT = 'Acte';
export const LABEL_ACTABILITY = 'Actabilité';
export const LABEL_ACTABLE = 'Actable';
export const LABEL_ADD_COPURCHASER_CONFIRM_TEXT = 'Souhaitez-vous renseigner un co-acquéreur ?';
export const LABEL_ADDITIONAL_DOCUMENTS = 'Annexes complémentaires';
export const LABEL_ALERT_RECIPIENT = "Destinataire de l'alerte:";
export const LABEL_AND_MORE = 'et +';
export const LABEL_ARE_YOU_INDIVIDUAL = 'Vous êtes un particulier ?';
export const LABEL_ARE_YOU_PROFESSIONAL = 'Vous êtes un professionnel ?';
export const LABEL_BANK = 'Banque';
export const LABEL_BANK_CHECK = 'Chèque';
export const LABEL_BANK_TRANSFER = 'Virement';
export const LABEL_BECOME_PARTNER = 'Devenir partenaire';
export const LABEL_BIC = 'BIC';
export const LABEL_BOOKING_FILE = 'Dossier de réservation';
export const LABEL_CASH_PAYMENT = 'Paiement comptant';
export const LABEL_CHECK_REMITTANCE_DATE = 'Date de remise de chèque';
export const LABEL_CHECK_REMITTANCE_DATE_ERROR = 'Le champ Date de remise de chèque est incorrect';
export const LABEL_CREATED_WITH_DATE = `Créé le ${TOKEN_DATE}`;
export const LABEL_CREDIT = 'Crédit';
export const LABEL_DEPOSIT_AMOUNT = 'Montant du dépôt de garantie';
export const LABEL_DEPOSIT_AMOUNT_ERROR = 'Le champ Montant du dépôt de garantie est incorrect';
export const LABEL_DESCRIPTIVE_NOTICE = 'Notice descriptive';
export const LABEL_DESCRIPTIVE_NOTICE_ERROR = 'Le champ Notice descriptive est incorrect';
export const LABEL_FORM_GENERATE_CONTRACT_PERCENTAGE_FEES_INFO1 =
  'Indication du taux de base correspondant au mandat cadre ou à l’avenant.';
export const LABEL_FORM_GENERATE_CONTRACT_PERCENTAGE_FEES_INFO2 =
  'Si le taux est erroné, la reservation ne sera pas prise en compte.';
export const LABEL_FORM_GENERATE_CONTRACT_PERCENTAGE_FEES_PASTILLE =
  '*Sous réserve de validation de votre animateur';
export const LABEL_FUNDING = 'Financement';
export const LABEL_FUNDING_ERROR = 'Le champ Financement est incorrect';
export const LABEL_PAYMENT_METHOD = 'Mode de paiement';
export const LABEL_PAYMENT_METHOD_ERROR = 'Le champ Mode de paiement est incorrect';
export const LABEL_PERCENTAGE_FEES = 'Pourcentage des honoraires';
export const LABEL_PERCENTAGE_FEES_ERROR = 'Le champ Pourcentage des honoraires est incorrect';
export const LABEL_PERSONAL_CONTRIBUTION = 'Apport personnel';
export const LABEL_PERSONAL_CONTRIBUTION_ERROR = 'Le champ Apport personnel est incorrect';
export const LABEL_PROGRAM_REPORT_TITLE = 'Suivi de chantier';
export const LABEL_SIGNATURE_DATE_ERROR = 'Le champ Date de signature est incorrect';
export const LABEL_SPECIAL_CONDITIONS_DOCUMENTS = 'Documents conditions particulières';
export const LABEL_SPECIAL_CONDITIONS_DOCUMENTS_ERROR =
  'Le champ Documents conditions particulières est incorrect';
export const LABEL_TAX_MANAGEMENT = 'Gestion fiscal';
export const LABEL_TAX_MANAGEMENT_ERROR = 'Le champ Gestion fiscal est incorrect';
export const LABEL_TOTAL_LOAN = 'Total du prêt';
export const LABEL_TOTAL_LOAN_ERROR = 'Le champ Total du prêt est incorrect';
export const LABEL_LOT_PRICE_ERROR = 'Le champ Prix du lot est incorrect';
export const LABEL_CIVILITY_ERROR = 'Le champ Civilité est requis.';
export const LABEL_EMAIL_ERROR = 'Le champ Email est incorrect.';
export const LABEL_EMAIL_SAME_ERROR =
  'Veuillez saisir une adresse email distincte pour le co-acquéreur';
export const LABEL_EMAIL_SAME_TEST = 'coPrescriptor-same-email';
export const LABEL_PHONE_NUMBER_ERROR = 'Le champ Téléphone est incorrect';
export const LABEL_PHONE_NUMBER_MOBILE_ERROR =
  'Le champ Téléphone doit contenir un numéro de téléphone mobile';
export const LABEL_PHONE_NUMBER_SAME_ERROR =
  'Veuillez saisir un numéro de téléphone distinct pour le co-acquéreur.';
export const LABEL_PHONE_NUMBER_MOBILE_FORMAT_TEST = 'test-mobile-phone-format';
export const LABEL_PHONE_NUMBER_SAME_TEST = 'coPrescriptor-same-phone-number';
export const LABEL_3_LOTS_MAX = '3 lots max';
export const LABEL_ACCESS_COMPARISON_REFUSED =
  'Veuillez consulter votre comparaison sur votre ordinateur ou tablette.';
export const LABEL_ACTABILITY_DATE = "Date d'actabilité";
export const LABEL_ACTIVITY_INFOPANEL_TITLE = 'Détail de la réservation';
export const LABEL_ACTIVITY_INFOPANEL_SALE = 'Montant de la vente';
export const LABEL_ACTIVITY_INFOPANEL_OPTIONS = 'Options';
export const LABEL_ACTIVITY_NO_OPTION = 'Aucune option pour cette réservation';
export const LABEL_ACTIVITY_OPTIONS = 'Options';
export const LABEL_ACTIVITY_SALES_AIDS = 'Aides à la vente';
export const LABEL_ACTIVITY_SALES_AIDS_FOOTNOTE =
  '*Vous consentez à vous être référés auprès de votre animateur commercial de l’acceptation de(s) aide(s) à la vente, au risque d’un refus de votre réservation par ce dernier. ';
export const LABEL_ACTIVITY_NO_AIDS = 'Aucune aide à la vente pour cette réservation';
export const LABEL_ACTUALITY = 'Actualité';
export const LABEL_ADD_TO_A_FOLDER = 'Ajouter à un dossier';
export const LABEL_ADD_TO_A_FOLDER_TOOLTIP = 'Sélectionnez un lot à ajouter';
export const LABEL_ADD_TO_FOLDER_CREATE_FOLDER = 'Créer un nouveau dossier';
export const LABEL_ADDRESS = 'Adresse';
export const LABEL_ALERT_CREATE_SUCCESS = `Alerte pour le lot ${TOKEN_LOT_NUMBER} enregistrée !`;
export const LABEL_ALERT_CREATE_SUCCESS_SUBTEXT_MALE =
  'Vous serez alerté par email lorsque le statut du lot sera libre';
export const LABEL_ALERT_CREATE_SUCCESS_SUBTEXT_FEMALE =
  'Vous serez alertée par email lorsque le statut du lot sera libre';
export const LABEL_ALERT_CREATE_FAIL = `L'alerte pour le lot ${TOKEN_LOT_NUMBER} n'a pas pu être enregistrée`;
export const LABEL_ALERT_DELETE_SUCCESS = `Alerte pour le lot ${TOKEN_LOT_NUMBER} supprimée !`;
export const LABEL_ALERT_DELETE_FAIL = `L'alerte pour le lot ${TOKEN_LOT_NUMBER} n'a pas pu être supprimée`;
export const LABEL_ALERT_MARKETING_CREATE_SUCCESS = `Votre alerte a bien été enregistrée !`;
export const LABEL_ALERT_MARKETING_CREATE_SUCCESS_SUBTEXT_MALE =
  'Vous serez alerté par email lors de la mise en vente';
export const LABEL_ALERT_MARKETING_CREATE_SUCCESS_SUBTEXT_FEMALE =
  'Vous serez alertée par email lors de la mise en vente';
export const LABEL_ALERT_MARKETING_CREATE_FAIL = `Votre alerte n'a pas pu être enregistrée`;
export const LABEL_ALERT_MARKETING_DELETE_SUCCESS = `Votre alerte a bien été supprimée`;
export const LABEL_ALERT_MARKETING_DELETE_SUCCESS_MULTIPLE = `Vos alertes ont bien été supprimées !`;
export const LABEL_ALERT_MARKETING_DELETE_FAIL = `Votre alerte n'a pas pu être supprimée`;
export const LABEL_ALERT_MARKETING_DELETE_FAIL_MULTIPLE = `Vos alertes n'ont pas pu être supprimées`;
export const LABEL_ALLOW = 'Autoriser';
export const LABEL_ANCHOR_3D = 'Résidence en 3D';
export const LABEL_ANCHOR_DESCRIPTION = 'Descriptif';
export const LABEL_ANCHOR_DOCUMENTS = 'Documents';
export const LABEL_ANCHOR_GALLERY = 'Galerie';
export const LABEL_ANCHOR_LOCATION = 'Localisation';
export const LABEL_ANCHOR_PRICES_GRID = 'Grille de prix';
export const LABEL_ANCHOR_REPORT = 'Suivi de chantier';
export const LABEL_ANCHOR_SERVICES = 'Services';
export const LABEL_ANCHOR_VIDEO = 'Vidéo';
export const LABEL_ANNEX = 'Annexe';
export const LABEL_APPARTMENT_TYPE = "Type d'appartement";
export const LABEL_AVAILABLE_LOTS_ALERT_SINGLE = `Plus que ${TOKEN_NB} lot disponible sur ce programme !`;
export const LABEL_AVAILABLE_LOTS_ALERT_PLURAL = `Plus que ${TOKEN_NB} lots disponibles sur ce programme !`;
export const LABEL_BALCONY = 'Balcon';
export const LABEL_BOOKING = 'Réservation';
export const LABEL_BOOKLET = 'Brochure';
export const LABEL_CANCEL_BUTTON = 'Annuler';
export const LABEL_CARD_LOT = 'Fiche lot';
export const LABEL_CARD_PROGRAM = 'Fiche programme';
export const LABEL_CAVE = 'Cave';
export const LABEL_CHAUFFAGE_TYPE = 'Type de chauffage';
export const LABEL_CITIES = 'Villes';
export const LABEL_CITY = 'Ville';
export const LABEL_CLIENT_NAME = 'Nom du client';
export const LABEL_CLEAR_ALL_CRITERIAS = 'Effacer tous les critères';
export const LABEL_CLOSE = 'Fermer';
export const LABEL_COMPANY_NAME = 'Raison sociale';
export const LABEL_COMPARE = 'Comparer';
export const LABEL_COMPARE_TOOLTIP = 'Sélectionnez des lots à comparer';
export const LABEL_COMPARISON = 'Comparaison';
export const LABEL_CONFIRM_BOOKLET_SEND = 'La brochure a bien été envoyée';
export const LABEL_CONFIRM_CANCEL_OPTION = 'Merci, votre Option a bien été annulée';
export const LABEL_CONFIRM_CANCEL_OPTION_FAIL = "Votre Option n'a pas pu être annulée";
export const LABEL_CONFIRM_CANCEL_PREBOOK = 'Merci, votre Pré-réservation a bien été annulée';
export const LABEL_CONFIRM_CANCEL_PREBOOK_FAIL = "Votre Pré-réservation n'a pas pu être annulée";
export const LABEL_CONFIRM_CARD_SEND = 'La fiche a bien été envoyée';
export const LABEL_CONFIRM_MAIL_SEND = 'Votre e-mail a bien été expédié';
export const LABEL_CONFIRM_DOCUMENTS_SEND = 'Les documents ont bien été envoyés';
export const LABEL_CONFIRM_OPTION = 'Confirmer mon option';
export const LABEL_CONFIRM_PRE_BOOK = 'Confirmer ma pré-réservation';
export const LABEL_CONFIRM_SAVE_SEARCH = 'Votre recherche est enregistrée !';
export const LABEL_CONFIRM_SAVE_SEARCH_DELETE_ERROR =
  'Erreur lors de la suppression de votre recherche';
export const LABEL_CONFIRM_SAVE_SEARCH_ERROR = "Erreur lors de l'enregistrement de votre recherche";
export const LABEL_CONFIRM_SAVE_SEARCH_MODIFICATION = 'Votre recherche a bien été modifiée';
export const LABEL_CONTACT = 'Contacter';
export const LABEL_CONTACT_REGIONAL_CONTACTS = 'Contactez vos interlocuteurs régionaux';
export const LABEL_CONTRACT_GENERATE = 'Le contrat a été généré';
export const LABEL_CONTROLLED_PRICE = 'Prix maîtrisé';
export const LABEL_CONTROLLED_PRICES_ONLY = 'Prix maîtrisés';
export const LABEL_COPURCHASER = 'Co-aquéreur';
export const LABEL_COUNTRY = 'Pays';
export const LABEL_CREATE_ALERT = 'Être alerté(e) par email lorsque le statut sera libre';
export const LABEL_CREATE_OPTION = 'Poser une option';
export const LABEL_DATE = `Le ${TOKEN_DATE}`;
export const LABEL_DATE_END_ON = 'FIN LE';
export const LABEL_DATE_EXPIRATION = `Expire le ${TOKEN_DATE}`;
export const LABEL_DATE_OF_BIRTH = 'Date de naissance (JJ/MM/AAAA)';
export const LABEL_DATE_PDF = `Document non contractuel édité le ${TOKEN_DATE}`;
export const LABEL_DEFAULT_JOB = 'Animateur commercial';
export const LABEL_DEFAULT_SORT_SAVE_SUCCESS = 'Votre tri par défaut a bien été enregistré !';
export const LABEL_DEFAULT_SORT_SAVE_FAIL = "Votre tri par défaut n'a pas pu être enregistré.";
export const LABEL_DELETE = 'Supprimer';
export const LABEL_DELETE_ALERT = "Supprimer l'alerte par email";
export const LABEL_DELETE_COMPARISON = 'Supprimer la comparaison';
export const LABEL_DELETE_FOLDER = 'Supprimer le dossier';
export const LABEL_DELIVERY = 'Livraison';
export const LABEL_DELIVERY_SHORT = 'Livr.';
export const LABEL_DELIVERY_DATE = 'Date de livraison';
export const LABEL_DEPARTMENTS = 'Départements';
export const LABEL_DISABLE_MAIL_ALERT = "Désactiver l'alerte email";
export const LABEL_DISCONNECTION = 'Déconnexion';
export const LABEL_DISPLAY = 'Afficher';
export const LABEL_DISPLAY_CARD_PROGRAM = 'Afficher la fiche programme';
export const LABEL_DISPLAY_LOTS = 'Afficher les lots';
export const LABEL_DISPLAY_MAP = 'Afficher la carte';
export const LABEL_DISPLAY_MORE_OPTIONS = "Afficher plus d'options";
export const LABEL_DISPLAY_NB = 'Afficher les';
export const LABEL_DOCUMENT_NOT_FOUND = 'Le document est introuvable';
export const LABEL_DOCUMENTS = 'Documents';
export const LABEL_DOWNLOAD = 'Télécharger';
export const LABEL_DOWNLOAD_BOOKLET = 'Télécharger la brochure';
export const LABEL_DOWNLOAD_PRODUCT_BOOKLET = 'Télécharger la fiche produit';
export const LABEL_DOWNLOAD_CARD = 'Télécharger la fiche';
export const LABEL_DOWNLOAD_COMPARISON = 'Télécharger la comparaison';
export const LABEL_DOWNLOAD_CONTRACT = 'Télécharger le contrat';
export const LABEL_DOWNLOAD_FOLDER = 'Télécharger le dossier';
export const LABEL_DRAG = 'Glisser pour agrandir ou réduire le panneau';
export const LABEL_DRAG_AND_DROP_PROOF = 'Glisser-déposer le justificatif';
export const LABEL_EMAIL_ADDRESS = 'Adresse e-mail';
export const LABEL_EMAIL = 'Email';
export const LABEL_ENABLE_MAIL_ALERT = "Activer l'alerte email";
export const LABEL_END_DATE_TMA = 'Date de fin TMA';
export const LABEL_ERROR_404 = 'Erreur 404';
export const LABEL_ERROR_PAGE_DONT_EXIST = 'Oups… La page que vous cherchez n’existe plus…';
export const LABEL_ERROR_MAX = 'Le maximum ne peut excéder';
export const LABEL_ERROR_MIN_MAX = 'Le minimum doit être inférieur au maximum';
export const LABEL_ERROR_NO_LOCATION = 'Veuillez saisir une localité dans le champ de recherche !';
export const LABEL_EXPOSITION = 'Exposition';
export const LABEL_FAMILY_STATUS_PACS = 'PACS';
export const LABEL_FAMILY_STATUS_COHABITATION = 'Concubinage';
export const LABEL_FAMILY_STATUS_DIVORCED = 'Divorcé.e';
export const LABEL_FAMILY_STATUS_MARRIED = 'Marié.e';
export const LABEL_FAMILY_STATUS_SINGLE = 'Célibataire';
export const LABEL_FAMILY_STATUS_WIDOW = 'Veuf / Veuve';
export const LABEL_FAIL_DOWNLOAD_CONTRACT = "Le contrat n'a pas pu être téléchargé";
export const LABEL_FAIL_DOWNLOAD_NO_CONTRACT = "Le contrat demandé n'existe pas";
export const LABEL_FAIL_DOWNLOAD_FILE = "Le document n'a pas pu être téléchargé";
export const LABEL_FAMILY_STATUS = 'Statut familial';
export const LABEL_FILE_FORMAT_ERROR = "Ce fichier n'est pas au bon format.";
export const LABEL_FILE_FORMAT_ERROR_HELPER_TEXT = `Types de fichiers autorisés: ${TOKEN_TYPES}.`;
export const LABEL_FILE_SIZE_ERROR = 'Ce fichier dépasse la limite autorisée';
export const LABEL_FILE_SIZE_ERROR_HELPER_TEXT = `La limite maximale est de ${TOKEN_MAX_SIZE_PDF} pour un fichier PDF et de ${TOKEN_MAX_SIZE_IMG} pour une image`;
export const LABEL_FINISH = 'Terminer';
export const LABEL_FISCAL_AREA = 'Zone fiscale';
export const LABEL_FISCALITY = 'Fiscalité';
export const LABEL_FOLDER_DELETE = 'Suppression du dossier';
export const LABEL_FLOOR = 'Étage';
export const LABEL_FROM = 'À partir de';
export const LABEL_FREE_LOTS_ONLY = 'Lots libres';
export const LABEL_FURNISHED = 'Pack meublé';
export const LABEL_GARDEN = 'Jardin';
export const LABEL_GENERATE_CONTRACT = 'Générer le contrat';
export const LABEL_GENERATE_RESERVATION_CONTRACT = 'Générer le contrat de réservation';
export const LABEL_GROUND_PLAN = 'Plan de masse';
export const LABEL_MODIFY_RESERVATION_CONTRACT = 'Modifier le contrat de réservation';
export const LABEL_HIDE_INTEREST_POINTS = "Masquer les points d'intérêts";
export const LABEL_HONO_BOOST = 'Hono Boost';
export const LABEL_HOUSING = 'Logement';
export const LABEL_HOUSING_ENVIRONMENT = "L'environnement de la résidence";
export const LABEL_HOUSING_INFORMATIONS = 'Informations Logement';
export const LABEL_HT = 'HT';
export const LABEL_HT_IMMO = 'HT du prix HT hors immobilier';
export const LABEL_HT_TTC = 'HT du prix TTC';
export const LABEL_IBAN = 'IBAN';
export const LABEL_IMPORT_DOCUMENT = 'Importer un document';
export const LABEL_INFORMATIONS_EDITED = 'Vos informations ont bien été enregistrées';
export const LABEL_INTEREST_POINTS = "Points d'intérêts";
export const LABEL_INVITATION_TEXT =
  "Nous vous invitons à vous rendre sur notre site Internet pour retrouver l'intégralité de l'offre VINCI Immobilier.";
export const LABEL_KIND = 'Type de bien';
export const LABEL_KITCHEN_LOT_DETAIL_TITLE = 'Cuisine équipée';
export const LABEL_KITCHEN_LOT_DETAIL_OPTION = `En option PAYANTE à ${TOKEN_PRICE}${NON_BREAKING_SPACE}TTC`;
export const LABEL_KITCHEN_LOT_DETAIL_INCLUDED = 'OFFERTE';
export const LABEL_KITCHEN_OPTION = `Ma cuisine équipée payante à ${TOKEN_PRICE}${NON_BREAKING_SPACE}TTC*`;
export const LABEL_KITCHEN_OPTION_SUBTEXT = '* inclus dans le montant de la vente';
export const LABEL_KITCHEN_OPTION_TOTAL = `Soit un prix de vente total pour ce lot à ${TOKEN_PRICE}${NON_BREAKING_SPACE}TTC`;
export const LABEL_KNOW_MORE = 'En savoir plus';
export const LABEL_FIRSTNAME = 'Prénom';
export const LABEL_LAUNCH_ALERT_ENABLE = "M'alerter de la mise en vente";
export const LABEL_LAUNCH_ALERT_DISABLE = 'Alerte enregistrée';
export const LABEL_LASTNAME = 'Nom';
export const LABEL_FULLNAME = 'Nom prénom';
export const LABEL_LAST_SEND = 'Dernier envoi';
export const LABEL_LAST_STEP = 'Étape précédente';
export const LABEL_LATER = 'Ultérieurement';
export const LABEL_LMNP = 'LMNP';
export const LABEL_LOGGIA = 'Loggia';
export const LABEL_LOT = 'Lot';
export const LABEL_LOT_DETAIL = 'Détail du lot';
export const LABEL_LOT_NUMBER = 'Lot n°';
export const LABEL_LOT_PRICE = 'Prix du lot';
export const LABEL_LOTS = 'lots';
export const LABEL_MADAME = 'Madame';
export const LABEL_MANAGER = 'Gestionnaire';
export const LABEL_MARKETING_DATE = 'Date de commercialisation';
export const LABEL_MAX = 'MAX';
export const LABEL_MAX_SIZE_IMG = 'Taille maximum Image';
export const LABEL_MAX_SIZE_PDF = 'Taille maximum PDF';
export const LABEL_MIN = 'MIN';
export const LABEL_MISTER = 'Monsieur';
export const LABEL_MOBILE_PHONE = 'Téléphone portable';
export const LABEL_MODIFY = 'Modifier';
export const LABEL_MY_ACCOUNT = 'Mon compte';
export const LABEL_MY_ACTIVITY = 'Mon activité';
export const LABEL_INTERLOCUTORS = 'Mes interlocuteurs';
export const LABEL_MY_NOTIFICATIONS = 'Mes notifications';
export const LABEL_MY_SAVED_SEARCH_PLURAL = 'Mes recherches enregistrées';
export const LABEL_MY_SEARCH = 'Ma recherche';
export const LABEL_MY_SEARCH_DELETE_CONFIRM = 'Votre recherche a bien été supprimée';
export const LABEL_MY_SEARCH_EMPTY =
  "Enregistrez dès maintenant vos recherches et soyez alerté par email lors de la publication d'un nouveau programme, lot ou lorsqu'un lot est libéré.";
export const LABEL_MY_SEARCH_PLURAL = 'Mes recherches';
export const LABEL_MY_SEARCH_MAIL = "Adresse email destinataire de l'alerte";
export const LABEL_MY_SEARCH_MAIL_ERROR =
  "Veuillez saisir une adresse email destinataire de l'alerte";
export const LABEL_MY_SEARCH_NAME = 'Nom de ma recherche';
export const LABEL_MY_SEARCH_NAME_ERROR = 'Le nom de votre recherche est incorrect.';
export const LABEL_MY_SEARCH_NAME_ERROR_LENGTH =
  'Le nom de votre recherche est limité à 30 caractères maximum, veuillez en saisir un autre.';
export const LABEL_NATIONALITY = 'Nationalité';
export const LABEL_NATURE = 'Nature';
export const LABEL_NB_OPTION_DURATION = `Option valable ${TOKEN_NB} jours calendaires`;
export const LABEL_NB_OPTIONS = `${TOKEN_NB} options simultanées`;
export const LABEL_NB_PRE_BOOKING_DURATION = `Pré-réservation valable ${TOKEN_NB} jours calendaires`;
export const LABEL_NB_PRE_BOOKINGS = `${TOKEN_NB} pré-réservations simultanées`;
export const LABEL_NEW_PROGRAMS_TITLE = 'Nos nouvelles résidences';
export const LABEL_NEW_RESIDENCE = 'Nouvelle résidence';
export const LABEL_NEWS = 'Nouveautés';
export const LABEL_NEWS_TITLE = 'Actualités';
export const LABEL_NEXT_STEP = 'Étape suivante';
export const LABEL_NO = 'Non';
export const LABEL_NO_COMMERCIAL_RIGHTS =
  "Vous n'&apos;'avez aucun droit commercial pour le moment";
export const LABEL_NO_DOCUMENT = 'Aucun document trouvé';
export const LABEL_NO_FEES = 'Aucun honoraire';
export const LABEL_NO_LOT = 'Aucun lot';
export const LABEL_NO_PROOF_SENT = 'Aucun justificatif transmis.';
export const LABEL_NORMAL_VAT = 'TVA normale';
export const LABEL_NOT_PARNTER_TEXT_ONE = "Vous n'êtes pas encore partenaire";
export const LABEL_NOT_PARNTER_TEXT_TWO = 'et souhaitez le devenir ?';
export const LABEL_NOT_SPECIFIED = 'Non renseigné';
export const LABEL_NOTARY_FEES = 'Frais de notaire';
export const LABEL_NOW = 'Maintenant';
export const LABEL_NUDE_PROPERTY_TTC = 'Nue Propriété TTC';
export const LABEL_OFFFERED = 'offerts';
export const LABEL_OPEN_SLIDESHOW = 'Ouvrir le diaporama';
export const LABEL_OPPORTUNITIES = 'Nos opportunités';
export const LABEL_OPTIONATE = 'Optionner';
export const LABEL_OPTIONS = 'Options';
export const LABEL_OR = 'ou';
export const LABEL_ORDER = 'Ordre';
export const LABEL_OTHER_CRITERIAS = 'Autres filtres';
export const LABEL_PARKING = 'Parking';
export const LABEL_PENDING_VALIDATION_TEXT =
  "Vinci immobilier Patrimoine vous remercie pour votre demande d'inscription. Nos équipes vous contacteront très prochainement afin de finaliser avec vous votre adhésion. Pour toutes questions, nous vous invitons à nous adresser un e-mail ou nous contacter par téléphone. E-mail : vip@vinci-immobilier.com Tél . : 01 55 38 80 01";
export const LABEL_PERCENT_HOUSING = `${TOKEN_NB}% en logement`;
export const LABEL_PERCENT_SERVICE_RESIDENCE = `${TOKEN_NB}% en résidence service`;
export const LABEL_PERSPECTIVES = 'Perspectives';
export const LABEL_PLACEHOLDER_TEXT = `Pour afficher ${TOKEN_NAME}, vous devez accepter les cookies ${TOKEN_CATEGORY} ou ${TOKEN_VENDOR}.`;
export const LABEL_PLAN = 'Plan';
export const LABEL_PHONE = 'Téléphone fixe';
export const LABEL_PLACE_OF_BIRTH = 'Lieu de naissance';
export const LABEL_POSITION = 'Ma position';
export const LABEL_POSITION_MODAL_TITLE = 'Préférence de tri par défaut selon votre position';
export const LABEL_POSTAL_ADDRESS = 'Adresse postale';
export const LABEL_POTENTIAL = 'potentiel';
export const LABEL_PRE_BOOK = 'Pré-réserver';
export const LABEL_PRE_BOOKING = 'Pré-réservation';
export const LABEL_PRE_BOOKINGS = 'Pré-réservations';
export const LABEL_PRE_BOOKING_CANCEL_MOTIVE_PROSPECT = "Annulation à l'initiative du prescripteur";
export const LABEL_PREFERRED_LOCATION_BUTTON = 'Modifier ma position';
export const LABEL_PREVIEW = 'Avant première';
export const LABEL_PRICE_COMMERCIAL_TTC = 'Prix commercial TTC';
export const LABEL_PRICE_IMMO = 'Prix immo';
export const LABEL_PRICE_IMMO_HT = 'Prix immo. HT';
export const LABEL_PRICE_INCLUDING_TAX_NORMALE = 'Prix immo (TVA Normal)';
export const LABEL_PRICE_INCLUDING_TAX_TTC = 'Prix immo. TTC';
export const LABEL_PRICE_MOB_HT = 'Prix mob. HT';
export const LABEL_PRICE_MOBILIER_HT = 'Prix mobilier HT';
export const LABEL_PRICE_OF = 'Prix de';
export const LABEL_PRICE_REDUCED_VAT = 'Prix immo. (TVA Réduite)';
export const LABEL_PRINT = 'Imprimer';
export const LABEL_PRODUCT_BOOKLET = 'Brief commercial';
export const LABEL_PROFITABILITY = 'Rentabilité';
export const LABEL_PROFITABILITY_HT = 'Rentabilité HT';
export const LABEL_PROGRAM = 'Programme';
export const LABEL_PROGRAM_CITY = 'Programme / Ville';
export const LABEL_PROGRAMS = 'programmes';
export const LABEL_PROMO = 'Promo';
export const LABEL_PROMOTION_ACTIVITIES_FORM =
  'Remise à déduire du prix de vente affiché. Merci de contacter votre animateur commercial';
export const LABEL_PURCHASER = 'Acquéreur';
export const LABEL_PURCHASER_IDS = "Pièce d'identité acquéreurs";
export const LABEL_PURCHASE_DESTINATION = "Destination de l'achat";
export const LABEL_QUIT = 'Quitter';
export const LABEL_RANGE_PRICE = 'Tranche de prix';
export const LABEL_RANGE = `De ${TOKEN_MIN} à ${TOKEN_MAX}`;
export const LABEL_RANGE_SHORT = `${TOKEN_MIN} à ${TOKEN_MAX}`;
export const LABEL_RANGE_LOWER = `À partir de ${TOKEN_MIN}`;
export const LABEL_RANGE_HIGHER = `Jusqu'à ${TOKEN_MAX}`;
export const LABEL_READ_LESS = 'Voir moins';
export const LABEL_READ_MORE = 'Lire la suite';
export const LABEL_REDUCED_VAT = 'TVA réduite';
export const LABEL_VAT_5_5 = 'TVA 5,5%';
export const LABEL_REGIONS = 'Régions';
export const LABEL_RELOAD_PAGE = 'Recharger la page';
export const LABEL_RENAME_FOLDER = 'Renommer le dossier';
export const LABEL_RENAME_FOLDER_MODAL_TITLE = 'Comment souhaitez-vous renommer le dossier ?';
export const LABEL_RENT = 'Loyer';
export const LABEL_RENT_LMNP = 'Loyer LMNP HT';
export const LABEL_RENT_LMNP_YEAR = 'Loyer annuel LMNP';
export const LABEL_RENT_MARKET = 'Loyer de marché';
export const LABEL_RENT_MARKET_MONTH = 'Loyer du marché mensuel';
export const LABEL_RENT_PINEL = 'Loyer Pinel';
export const LABEL_RESIDENCE_TYPE = 'Type de résidence';
export const LABEL_RETURN = 'Retour';
export const LABEL_ROUTE = 'Itinéraire';
export const LABEL_SALES_AID_NOTARY = 'Frais de notaire offerts';
export const LABEL_SALES_AID_KITCHEN = 'Ma cuisine équipée offerte';
export const LABEL_SAVE = 'Enregistrer';
export const LABEL_SAVE_ALERT_MARKETING = "Enregistrer l'alerte";
export const LABEL_SAVE_IN_FOLDER = 'Enregistrer dans un dossier';
export const LABEL_SAVE_MY_SEARCH = 'Enregistrer ma recherche';
export const LABEL_SAVE_MY_SEARCH_TOOLTIP =
  'Enregistrer cette recherche et être alerté par email lors de la publication d’un nouveau programme, lot ou lorsqu’un lot est libéré.';
export const LABEL_SAVE_SEARCH_ALERT_FIELD_LABEL =
  "Être alerté par email lors de la publication d’un nouveau programme, lot ou lorsqu'un lot est libéré.";
export const LABEL_SAVE_TO_FOLDER = 'Enregistrer dans mes dossiers';
export const LABEL_SAVE_SEARCH_BUTTON_POPIN_TEXT =
  'Vous pouvez retrouver l’ensemble de vos recherches dans votre espace « Mes recherches »';
export const LABEL_SAVE_SEARCH_BUTTON_POPIN_TITLE = 'Votre recherche est enregistrée !';
export const LABEL_SAVED_SEARCH = 'Recherche enregistrée';
export const LABEL_SEARCH = 'Rechercher';
export const LABEL_SEARCH_AREA = 'Rechercher dans la zone';
export const LABEL_SEARCH_DELETE = 'Suppression de votre recherche';
export const LABEL_SEARCH_DELETE_CONFIRM_TEXT =
  'Êtes-vous sûr de vouloir supprimer cette recherche ?';
export const LABEL_SEARCH_SUGGESTION = 'Suggestion de recherche';
export const LABEL_SECURITY_DEPOSIT = 'Dépôt de garantie';
export const LABEL_SEE_COMPARISON = 'Voir la comparaison';
export const LABEL_SEE_MANAGEMENT_MANDATE = 'Voir le mandat de gestion';
export const LABEL_SEE_MORE_NEWS = "Voir plus d'actualités";
export const LABEL_SEE_PRICE_LIST = 'Voir la grille tarifaire';
export const LABEL_SEE_BOOKING = 'Voir la réservation';
export const LABEL_SEE_MY_SEARCH = 'Voir mes recherches';
export const LABEL_SEE_PRE_BOOKING = 'Voir la pré-réservation';
export const LABEL_SEE_SALE = 'Voir la vente';
export const LABEL_SEE_SEARCH_RESULTS = 'Voir les résultats de recherche';
export const LABEL_SELECT_ALL = `Tout sélectionner`;
export const LABEL_SELL_INCLUSION = 'Inclus dans la vente';
export const LABEL_SEND = 'Envoyer';
export const LABEL_SEND_ALL_DATAS = 'Envoyer tous les documents lot(s) et programme(s) du dossier';
export const LABEL_SEND_BOOKLET = 'Envoyer la brochure';
export const LABEL_SEND_PRODUCT_BOOKLET = 'Envoyer la fiche produit';
export const LABEL_SEND_CARD = 'Envoyer la fiche';
export const LABEL_SEND_COMPARISON = 'Envoyer la comparaison';
export const LABEL_SEND_DOCUMENTS = 'Envoyer les documents';
export const LABEL_SEND_LOT_CARD = 'Envoyer la fiche lot';
export const LABEL_SEND_PROGRAM_CARD = 'Envoyer la fiche programme';
export const LABEL_SEND_SIGNATURE_ERROR =
  "Une erreur s'est produite lors de l'envoi des documents.";
export const LABEL_SEND_SIGNATURE_ERROR_DEFAULT =
  "Votre demande de signature numérique n'a pas été envoyée.";
export const LABEL_SEND_SIGNATURE_ERROR_MESSAGE =
  'Afin de répondre au mieux à votre demande, merci de contacter votre animateur commercial.';
export const LABEL_SEND_FOLDER_DOCUMENTS = `Envoyer les documents du dossier ${TOKEN_FOLDER_NAME}`;
export const LABEL_SEND_FOLDER_PROGRAM = `Envoyer les documents du programme ${TOKEN_PROGRAM_NAME}`;
export const LABEL_SEND_LOADING = 'Envoi en cours...Veuillez patienter';
export const LABEL_SEND_SIGNATURE_NOT_OWNER =
  'Vous ne pouvez pas envoyer de demande de signature pour une pré-réservation qui ne vous appartient pas.';
export const LABEL_SEND_SIGNATURE_REQUEST = 'Envoyer la demande de signature';
export const LABEL_SEND_SIGNATURE_REQUEST_CLIENT = 'Envoyer la demande de signature au client';
export const LABEL_SEND_SIGNATURE_SUCCESS_TIME =
  'D’ici quelques minutes, vous serez notifiés par email de l’état du traitement.';
export const LABEL_SEND_SIGNATURE_SUCCESS_TITLE =
  'L’envoi de la demande de signature est en cours de traitement';
export const LABEL_SEND_SIGNATURE_SUCCESS_TITLE_LONG =
  'Le contrat de réservation a bien été envoyé au(x) client(s) par email afin de procéder à la signature électronique.';
export const LABEL_SEND_SIGNATURE_SUCCESS_SUBTITLE_LONG =
  'Une fois signé par le(s) client(s), vous serez notifié par une alerte sur votre espace partenaire.';
export const LABEL_SEND_SIGNATURE_SUCCESS_NOTIFY =
  "Pour suivre l'évolution de votre réservation, accedez à votre espace";
export const LABEL_SERVICE_RESIDENCE = 'Résidence service';
export const LABEL_SERVICES = 'Services';
export const LABEL_SHARE = 'Partager';
export const LABEL_SHOW_DETAIL = 'Voir le détail';
export const LABEL_SHOW_ACTIVITY_DETAIL = 'Voir le détail de la réservation';
export const LABEL_SHOW_LOT_DETAIL = 'Voir les détails du lot';
export const LABEL_SHOW_PLAN = 'Voir le plan';
export const LABEL_SIGNATURE_DATE = 'Date de signature';
export const LABEL_SITUATION = 'Situation actuelle';
export const LABEL_SITUATION_HOSTED = 'Hébergé.e';
export const LABEL_SITUATION_INVESTOR = 'Investisseur.euse';
export const LABEL_SITUATION_MULTI_OWNER = 'Multi-propriétaire';
export const LABEL_SITUATION_OWNER = 'Propriétaire';
export const LABEL_SITUATION_TENANT = 'Locataire';
export const LABEL_SOON_TITLE = 'Prochainement';
export const LABEL_SORT_BY = 'Trier par';
export const LABEL_STAIR = 'Étage';
export const LABEL_STATUS = 'Statut';
export const LABEL_STRONG_POINTS_TITLE = 'Les points forts';
export const LABEL_SURF = 'Surface hab.';
export const LABEL_SURF_ANNEX = 'Surf annexe';
export const LABEL_SURFACE = 'Surf hab.';
export const LABEL_SURFACE_ANNEX = 'Surface annexe';
export const LABEL_SURFACE_HABITABLE = 'Surface habitable';
export const LABEL_SURFACE_SIMPLE = 'Surface';
export const LABEL_TERRACE = 'Terrasse';
export const LABEL_TO = 'à';
export const LABEL_TODAY = "Aujourd'hui";
export const LABEL_TOO_MUCH_RESULTS = 'Trop de résultats !';
export const LABEL_TOO_MUCH_RESULTS_MODIFY = 'Veuillez affiner les critères de recherche !';
export const LABEL_TOO_MUCH_RESULTS_RANGE = `(Max: ${TOKEN_NB_LOTS} lots / ${TOKEN_NB_PROGRAMS} programmes)`;
export const LABEL_TRANSIT_BOX_INFOS = 'Cliquez sur les arrêts pour en savoir plus.';
export const LABEL_TRANSIT_BOX_TITLE = 'Transports en commun';
export const LABEL_TTC = 'TTC';
export const LABEL_TTC_PRICE = 'Prix TTC';
export const LABEL_TYPE = 'Type';
export const LABEL_TYPO = 'Typo';
export const LABEL_UNFURNISHED_PRICE = 'Prix hors mobilier';
export const LABEL_UNSELECT_ALL = `Tout désélectionner`;
export const LABEL_UNSELECT_FILE = `Désélectionner le fichier`;
export const LABEL_URL_VINCI = 'www.vinci-immobilier.com';
export const LABEL_VALID_FORMAT = 'Format accepté';
export const LABEL_VAT = 'TVA';
export const LABEL_VAT_NORMAL = 'TVA 20%';
export const LABEL_VAT_REDUCED = 'TVA réduite';
export const LABEL_VERSION = `Version ${TOKEN_VERSION}`;
export const LABEL_VI4YOU = 'VI4YOU';
export const LABEL_X_COMPARISON = `${TOKEN_NB} Comparaison`;
export const LABEL_X_COMPARISONS = `${TOKEN_NB} Comparaisons`;
export const LABEL_X_LOT = `${TOKEN_NB} Lot`;
export const LABEL_X_LOTS = `${TOKEN_NB} Lots`;
export const LABEL_X_PROGRAM = `${TOKEN_NB} Programme`;
export const LABEL_X_PROGRAMS = `${TOKEN_NB} Programmes`;
export const LABEL_YES = 'Oui';
export const LABEL_ZIP = 'ZIP';
export const LABEL_ZIPCODE = 'Code postal';

export const LABEL_DOCUMENTS_TYPE_TITLE_COMMERCE = 'Documents commerciaux';
export const LABEL_DOCUMENTS_TYPE_TITLE_COMPLEMENTAIRE = 'Documents complémentaires';
export const LABEL_DOCUMENTS_TYPE_TITLE_RESERVATION = 'Documents de réservation';

export const LABEL_PROFILE_MODIFY = 'Modifier';
export const LABEL_PROFILE_NO_CONTACTS = 'Aucun contact';
export const LABEL_PROFILE_TAB_ACCOUNT_TITLE = 'Informations personnelles';
export const LABEL_PROFILE_TAB_FEES_TITLE = 'Honoraires appliquées à mon mandat';
export const LABEL_PROFILE_TAB_INTERLOCUTOR_TITLE = 'Interlocuteurs VIP';

export const LABEL_TAB_ACCOUNT = 'Mon compte';
export const LABEL_TAB_INTERLOCUTORS = 'Mes interlocuteurs VIP';
export const LABEL_TAB_COMMERCIAL_RIGHTS = 'Mes droits commerciaux';
export const LABEL_TAB_FEES = 'Mes honoraires';

export const LABEL_TAB_OPTIONS = 'Options';
export const LABEL_TAB_PRERESERVATIONS = 'Pré-réservations';
export const LABEL_TAB_RESERVATIONS = 'Réservations';
export const LABEL_TAB_SALES = 'Actes';

export const LABEL_PAGE_TITLE_PROFILE = 'Mon compte';

export const LABEL_FOLDERS_CREATED_AT = 'Créé le';
export const LABEL_FOLDERS_CONFIRM_DELETE = 'Le dossier a été supprimé';
export const LABEL_FOLDERS_CONFIRM_QUESTION = 'Êtes-vous sûr de vouloir supprimer le dossier ?';
export const LABEL_FOLDERS_FIND_FOLDER = 'Rechercher un dossier';
export const LABEL_FOLDERS_MODIFIED_AT = 'Modifié le';
export const LABEL_FOLDERS_MY_FOLDERS = 'Mes dossiers';
export const LABEL_FOLDERS_NO_FOLDER = "Vous n'avez aucun dossier personnel pour l'instant";

export const LABEL_CREATE_FOLDER_TITLE = 'Comment souhaitez-vous nommer le dossier ?';
export const LABEL_CREATE_FOLDER_INPUT_LABEL = 'Nom de dossier';
export const LABEL_CREATE_FOLDER_CREATE_BUTTON = 'Créer le dossier';
export const LABEL_CREATE_FOLDER_ERROR_HELPER_TEXT = `Le nom de dossier "${TOKEN_FOLDER_NAME}" existe déjà, veuillez en saisir un autre.`;

export const LABEL_NEWSLETTER_TOOLTIP = 'Newsletter';
export const LABEL_NEWSLETTER_PANEL_TITLE = 'Newsletter';
export const LABEL_NEWSLETTER_LINK = 'Consultez la newsletter';

export const LABEL_NOTIFICATIONS_TITLE = 'Notifications';

export const LABEL_SEARCH_NO_LOCATION = 'Aucun résultat';
export const LABEL_SEARCH_NO_RESULTS = 'Aucun résultat ne correspond à vos critères';
export const LABEL_SEARCH_NO_RESULTS_MOBILE = 'Aucun résultat trouvé';
export const LABEL_SEARCH_NO_RESULTS_NEAR_PROGRAMS =
  'Mais ces programmes sont susceptibles de vous intéresser';
export const LABEL_SEARCH_PLACEHOLDER = 'Rechercher par région, département, ville, code postal';
export const LABEL_SEARCH_PLACEHOLDER_WHERE = 'Où ?';

export const LABEL_STEPS_OPTIONS_PREBOOKINGS_HEADER =
  'Gagnez du temps en procédant à la signature électronique en 5 étapes !';
export const LABEL_STEPS_OPTIONS_PREBOOKINGS_STEP_1 = 'Posez une pré-réservation';
export const LABEL_STEPS_OPTIONS_PREBOOKINGS_STEP_2 =
  "Générez le contrat et vérifiez l'exactitude des informations de votre client et du produit";
export const LABEL_STEPS_OPTIONS_PREBOOKINGS_STEP_3 = 'Envoyez la demande de signature au client';
export const LABEL_STEPS_OPTIONS_PREBOOKINGS_STEP_4 =
  'Votre client signe électroniquement le contrat à distance';
export const LABEL_STEPS_OPTIONS_PREBOOKINGS_STEP_5 = 'Contrat contre signé par VINCI Immobilier';

export const LABEL_STEPS_BOOKINGS_ACTS_HEADER =
  "Suivez toutes les étapes clés de la réservation à l'acte notarié";
export const LABEL_STEPS_BOOKINGS_ACTS_STEP_1 = 'Contrat contre signé par VINCI Immobilier';
export const LABEL_STEPS_BOOKINGS_ACTS_STEP_2 = 'Notification SRU par lettre recommandée';
export const LABEL_STEPS_BOOKINGS_ACTS_STEP_3 = 'Réception de votre dépôt de garantie';
export const LABEL_STEPS_BOOKINGS_ACTS_STEP_4 = 'Réception des justificatifs de financement';
export const LABEL_STEPS_BOOKINGS_ACTS_STEP_5 =
  'Transmission de l’offre de prêt ou de mise à disposition des fonds';
export const LABEL_STEPS_BOOKINGS_ACTS_STEP_6 = 'Signature de l’acte de vente définitif';

export const LABEL_LOT_ACTION_TOOLTIP_OPTION = 'Optionner le lot';
export const LABEL_LOT_ACTION_TOOLTIP_PREBOOK = 'Pré-réserver le lot';
export const LABEL_LOT_ACTION_TOOLTIP_CANCEL_OPTION = "Annuler l'option";
export const LABEL_LOT_ACTION_TOOLTIP_CANCEL_PREBOOK = 'Annuler la pré-réservation';
export const LABEL_LOT_ACTION_TOOLTIP_ADD_FOLDER = 'Ajouter à mes dossiers';
export const LABEL_LOT_ACTION_TOOLTIP_SEND_BOOKLET = 'Envoyer la brochure';

export const LABEL_LOTS_ENABLE_ERROR_TITLE = 'Oups...ce lot n’est plus consultable';
export const LABEL_LOTS_ENABLE_ERROR_SUBTITLE =
  'Afin de répondre au mieux à votre demande, merci de contacter votre animateur commercial.';

export const LABEL_GENERAL_INFORMATIONS = 'Informations générales';
export const LABEL_ADDITIONAL_INFORMATIONS = 'Informations annexes';
export const LABEL_FINANCIAL_INFORMATIONS = 'Informations financières';
export const LABEL_PURCHASER_INFORMATIONS = 'Informations Acquéreur';
export const LABEL_COPURCHASER_INFORMATIONS = 'Informations Co-acquéreur';

export const LABEL_EMAIL_RECEIVER = 'Destinataire';
export const LABEL_EMAIL_RECEIVER_HELPERTEXT_ERROR = 'Le champ Adresse e-mail est incorrect';
export const LABEL_EMAIL_SUBJECT = 'Objet';
export const LABEL_EMAIL_SUBJET_HELPERTEXT_ERROR = "Le champ Objet de l'email est incorrect.";
export const LABEL_EMAIL_CONTENT = 'Contenu du mail';
export const LABEL_FORM_FIELDS_REQUIRED = 'Champs obligatoires';
export const LABEL_EMAIL_FORM_ERROR_TITLE =
  "Une erreur est survenue lors de l'envoi de votre e-mail";
export const LABEL_FORM_ERROR_CONTENT =
  'Afin de répondre au mieux à votre demande, merci de contacter votre animateur commercial.';

export const LABEL_FORM_OPTION_TITLE = 'Informations de votre client';
export const LABEL_FORM_OPTION_SUCCESS_TITLE = 'Votre option a bien été posée';
export const LABEL_FORM_OPTION_CONTENT_DATE_AVAILABLE = "Votre option est valable jusqu'au ";
export const LABEL_FORM_OPTION_CONTENT_YOUR_SPACE = 'et visible dans votre espace ';
export const LABEL_FORM_OPTION_CONTENT_SOME_MINUTES =
  "D'ici quelques minutes, vous recevrez un email de confirmation";
export const LABEL_FORM_OPTION_ERROR_TITLE = 'L’option n’a pu être posée.';
export const LABEL_FORM_OPTION_ERROR_TITLE_DEGRADED_CRM = 'Option en attente de confirmation';
export const LABEL_FORM_ERROR_DEFAULT =
  'Afin de répondre au mieux à votre demande, merci de contacter votre animateur commercial.';
export const LABEL_FORM_ERROR_MESSAGE_DEGRADED_CRM = `Votre option pour le lot ${TOKEN_LOT_NUMBER} du programme ${TOKEN_PROGRAM_NAME} a été prise en compte par VI3P. Vous recevrez prochainement une confirmation par Email.`;

export const LABEL_FORM_PREBOOK_SUCCESS_TITLE = 'Votre pré-réservation a bien été prise en compte';
export const LABEL_FORM_GENERATE_CONTRACT_SUCCESS_TITLE =
  'La génération du contrat est en cours de traitement';
export const LABEL_FORM_GENERATE_CONTRACT_SUCCESS_SUB_TITLE =
  'D’ici quelques minutes, vous serez notifiés par email de l’état du traitement et votre contrat de réservation sera disponible sur votre espace';
export const LABEL_FORM_GENERATE_CONTRACT_SUCCESS_QUESTION =
  'Quand souhaitez vous envoyer le dossier de réservation au(x) client(s) pour la signature électronique ?';
export const LABEL_FORM_GENERATE_CONTRACT_MISSING_DOCUMENTS =
  'Le formulaire de l’étape 2 permettant la génération du contrat de réservation n’a pas pu être chargé en raison de l’absence de certains documents. Veuillez contacter votre animateur commercial.';
export const LABEL_MODAL_MISSING_DOCUMENTS_TITLE =
  "Le formulaire de génération de contrat de réservation n'a pas été chargé en raison de l'absence de certains documents.";
export const LABEL_MODAL_MISSING_DOCUMENTS_ADDITIONAL_TITLE =
  '(notice descriptive Mai 68, conditions particulières)';

export const LABEL_FORM_PREBOOK_CONTENT_DATE_AVAILABLE =
  "Votre pré-réservation est valable jusqu'au ";
export const LABEL_FORM_PREBOOK_ERROR_TITLE = 'La pré-réservation n’a pu être enregistrée.';
export const LABEL_FORM_PREBOOK_ERROR_TITLE_DEGRADED_CRM =
  'Pré-réservation en attente de confirmation';
export const LABEL_FORM_PREBOOK_ERROR_MESSAGE_DEGRADED_CRM = `Votre pré-réservation pour le lot ${TOKEN_LOT_NUMBER} du programme ${TOKEN_PROGRAM_NAME} a été prise en compte par VI3P. Vous recevrez prochainement une confirmation par Email.`;
export const LABEL_FORM_PREBOOK_INVESTOR = 'Investissement';
export const LABEL_FORM_PREBOOK_PRINCIPAL_RESIDENCE = 'Résidence principale';
export const LABEL_FORM_PREBOOK_SECOND_RESIDENCE = 'Résidence secondaire';

export const LABEL_ADDRESS_ERROR = 'Le champ Adresse est incorrect';
export const LABEL_CITY_ERROR = 'Le champ Ville est incorrect';
export const LABEL_COUNTRY_ERROR = 'Le champ Pays est incorrect';
export const LABEL_CURRENT_SITUATION_ERROR = 'Le champ Situation actuelle est incorrect';
export const LABEL_DATE_OF_BIRTH_ERROR = 'Le champ Date de naissance est incorrect';
export const LABEL_FAMILY_STATUS_ERROR = 'Le champ Statut familial est incorrect';
export const LABEL_FIRSTNAME_ERROR = 'Le champ Prénom est incorrect.';
export const LABEL_LASTNAME_ERROR = 'Le champ Nom est incorrect.';
export const LABEL_NATIONALITY_ERROR = 'Le champ Nationalité est incorrect';
export const LABEL_PLACE_OF_BIRTH_ERROR = 'Le champ Lieu de naissance est incorrect';
export const LABEL_ZIPCODE_ERROR = 'Le champ Code postal est incorrect.';

export const LABEL_FOLDER_SAVE_MESSAGE_COMPARISON = `La comparaison a bien été ajoutée au dossier ${TOKEN_FOLDER_NAME}`;
export const LABEL_FOLDER_SAVE_MESSAGE_LOT = `Le lot ${TOKEN_LIST} a bien été ajouté au dossier ${TOKEN_FOLDER_NAME}`;
export const LABEL_FOLDER_SAVE_MESSAGE_LOTS = `Les lots ${TOKEN_LIST} ont bien été ajoutés au dossier ${TOKEN_FOLDER_NAME}`;
export const LABEL_FOLDER_SAVE_MESSAGE_PROGRAM = `Le programme ${TOKEN_LIST} a bien été ajouté au dossier ${TOKEN_FOLDER_NAME}`;
export const LABEL_FOLDER_SAVE_MESSAGE_PROGRAMS = `Les programmes ${TOKEN_LIST} ont bien été ajoutés au dossier ${TOKEN_FOLDER_NAME}`;

export const LABEL_ACTIVITY_STEP = `Étape ${TOKEN_NB}`;
export const LABEL_ACTIVITY_STEP_CONTRACT = 'Générez\nle contrat';
export const LABEL_ACTIVITY_STEP_PREBOOK = 'Posez une\npré-réservation';
export const LABEL_ACTIVITY_STEP_REQUEST = 'Envoyez la\ndemande de\nsignature au client';
export const LABEL_ACTIVITY_STEP_SIGNED_CLIENT = 'Contrat signé\npar le client';
export const LABEL_ACTIVITY_STEP_SIGNED_VINCI = 'Contrat contre\nsigné par\nVINCI Immobilier';

export const LABEL_CONFIRMATION_ACTIVITY_STEP_CONTRACT = 'Générez le contrat';
export const LABEL_CONFIRMATION_ACTIVITY_STEP_PREBOOK = 'Posez une pré-réservation';
export const LABEL_CONFIRMATION_ACTIVITY_STEP_REQUEST = 'Envoyez la demande de signature au client';
export const LABEL_CONFIRMATION_ACTIVITY_STEP_SIGNED_CLIENT = 'Contrat signé par le client';
export const LABEL_CONFIRMATION_ACTIVITY_STEP_SIGNED_VINCI =
  'Contrat contre signé par VINCI Immobilier';

export const LABEL_MODAL_TITLE_CANCEL_OPTION = "Annulation de l'option";
export const LABEL_MODAL_TITLE_CANCEL_PREBOOK = 'Annulation de la pré-réservation';
export const LABEL_MODAL_CONTENT_CANCEL_OPTION = `Êtes-vous sur de vouloir annuler l'option sur le lot ${TOKEN_NB} ?`;
export const LABEL_MODAL_CONTENT_CANCEL_OPTION_EXP = `*Attention*, une fois annulée, vous ne pourrez plus optionner ce lot jusqu’au *${TOKEN_DATE}*`;
export const LABEL_MODAL_CONTENT_CANCEL_PREBOOK = `Êtes-vous sur de vouloir annuler la pré-réservation sur le lot ${TOKEN_NB} ?`;
export const LABEL_MODAL_CONTENT_CANCEL_PREBOOK_EXP = `*Attention*, une fois annulée, vous ne pourrez plus pré-réserver ce lot jusqu’au *${TOKEN_DATE}*`;
export const LABEL_MODAL_FAIL_CANCEL_OPTION_TITLE = "L'option n'a pas pu être annulée";
export const LABEL_MODAL_FAIL_CANCEL_PREBOOK_TITLE = "La pré-réservation n'a pas pu être annulée";
export const LABEL_MODAL_FAIL_CANCEL_BOLD = 'Veuillez réessayer ultérieurement.';
export const LABEL_MODAL_FAIL_CANCEL_CONTACT =
  "En cas de persistence de l'anomalie, merci de contacter votre animateur commercial.";

export const LABEL_FOLDER_TAB_COMPARISONS = `Les comparaisons (${TOKEN_NB})`;
export const LABEL_FOLDER_TAB_LOTS = `Les lots (${TOKEN_NB})`;
export const LABEL_FOLDER_TAB_PROGRAMS = `Les programmes (${TOKEN_NB})`;

export const LABEL_PROGRAM_404 = `Le programme ${TOKEN_REF} n'existe pas.`;
export const LABEL_PROGRAM_GALLERY = `Gallerie d'images pour le programme ${TOKEN_NAME}`;
export const LABEL_PROGRAM_GALLERY_CLOSE = 'Fermer la gallerie';
export const LABEL_PROGRAM_TAB_LOTS = 'Grille de prix';
export const LABEL_PROGRAM_TAB_DESCRIPTION = 'Descriptif de la résidence';
export const LABEL_PROGRAM_TAB_LOCATION = 'Localisation';
export const LABEL_PROGRAM_PRICE_TABS = 'Prix avec ou sans TVA réduite';
export const LABEL_PROGRAM_INFO_TITLE = 'Informations sur le programme';
export const LABEL_PROGRAM_DESCRIPTION_TITLE = `À propos de la résidence ${TOKEN_NAME}`;
export const LABEL_PROGRAM_INFO_TYPE = 'Type de résidence';
export const LABEL_PROGRAM_INFO_MANAGER = 'Gestionnaire';
export const LABEL_PROGRAM_INFO_NB = 'Nombre de lots total';
export const LABEL_PROGRAM_INFO_TYPOLOGY = 'Typologie des lots disponibles';
export const LABEL_PROGRAM_INFO_OCCUPATION = 'Occupation possible';
export const LABEL_PROGRAM_INFO_ADDRESS = 'Adresse';
export const LABEL_PROGRAM_INFO_SALESPOINT = 'Adresse bulle de vente';
export const LABEL_PROGRAM_INFO_HOURS = "Horaire d'ouverture bulle de vente";
export const LABEL_PROGRAM_INFO_PRODUCT = 'Gamme du produit';
export const LABEL_PROGRAM_INFO_ZONE = 'Zone fiscale';

export const LABEL_DOCUMENTS_SELECT_ALL = 'Tout sélectionner';
export const LABEL_DOCUMENTS_UNSELECT_ALL = 'Tout désélectionner';
export const LABEL_DOCUMENTS_DOWNLOAD = 'Télécharger';
export const LABEL_DOCUMENTS_SHARE = 'Envoyer par email';

export const LABEL_NB_AVAILABLE_LOT = `${TOKEN_NB} lot disponible`;
export const LABEL_NB_AVAILABLE_LOTS = `${TOKEN_NB} lots disponibles`;
export const LABEL_LOTS_NO_RESULT = "Aucun lot n'est actuellement disponible";

export const LABEL_LOAD_PROBLEM_ERROR_TITLE = 'Oups... un problème est survenu...';
export const LABEL_LOAD_PROBLEM_ERROR_SUBTITLE =
  'En cas de persistence du problème, merci de contacter votre animateur commercial';
export const LABEL_LOAD_PROBLEM_ERROR_RELOAD_LABEL = 'Erreur inattendue, veuillez';

export const LABEL_PDF_SEND_LOT_CARD = `Envoyer la fiche lot ${TOKEN_NAME}`;
export const LABEL_PDF_SEND_PROGRAM_CARD = `Envoyer la fiche programme ${TOKEN_NAME}`;
export const LABEL_PDF_EMAIL_RECEIVER = 'Email Destinataire';
export const LABEL_PDF_FIRSTNAME_HELPERTEXT_ERROR =
  'Le champ Prénom doit contenir au moins 3 caractères';
export const LABEL_PDF_LASTNAME_HELPERTEXT_ERROR =
  'Le champ Nom doit contenir au moins 3 caractères';

export const LABEL_REPORT_GALLERY = `Gallerie d'images pour le bulletin de chantier ${TOKEN_NAME}`;
export const LABEL_REPORT_GALLERY_OPEN = 'Consulter les visuels';
export const LABEL_REPORT_GALLERY_CLOSE = 'Fermer la gallerie';
