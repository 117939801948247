import type { Color } from 'types/ui';

import { LOT_PROMO_COL_WIDTH } from 'settings/lots';

export function hex2rgba(hex: string | undefined, alpha?: string | number | null) {
  const match = hex?.match(/(\w\w?)(\w\w?)(\w\w?)/);
  if (!match) {
    return undefined;
  }
  const [r, g, b] = match.slice(1).map(x => parseInt(x.length === 1 ? `${x}${x}` : x, 16));
  if (typeof alpha !== 'number' && typeof alpha !== 'string') {
    return `rgb(${r},${g},${b})`;
  }
  return `rgba(${r},${g},${b},${alpha})`;
}

export function colorToString(color: Color) {
  if (!color) {
    return undefined;
  }
  return hex2rgba(color.color, color.opacity);
}

export function getColumnsWidths(
  headings: { id: string }[],
  headingsWidth: Record<string, number>
) {
  const totalWeight = headings.map(h => headingsWidth[h.id] ?? 0).reduce((sum, w) => sum + w);

  if (headings.some(h => h.id === 'promo')) {
    return Object.fromEntries(
      headings.map(heading => {
        if (heading.id === 'promo') {
          return [heading.id, `${LOT_PROMO_COL_WIDTH}px`];
        }
        const weightedWidth = (100 * headingsWidth[heading.id]) / totalWeight;
        return [
          heading.id,
          `calc(${weightedWidth}% - ${LOT_PROMO_COL_WIDTH / (headings.length - 1)}px)`,
        ];
      })
    );
  }

  return Object.fromEntries(
    headings.map(heading => [heading.id, `${(100 * headingsWidth[heading.id]) / totalWeight}%`])
  );
}
