import { LOT_JSON_KIND, LOT_JSON_PROGRAM_REF } from 'settings/lots';
import {
  PROGRAM_ARRAY_LENGTH,
  PROGRAM_HOUSING_TYPE_SENIOR,
  PROGRAM_HOUSING_TYPE_STUDENT,
  PROGRAM_PHASE_PREVIEW,
} from 'settings/programs';
import {
  KINDS_ORDER,
  SEARCH_CRITERIA_CONTROLLED_PRICE,
  SEARCH_CRITERIA_FURNISHED,
  SEARCH_CRITERIA_HONOBOOST,
  SEARCH_CRITERIA_NEW,
  SEARCH_CRITERIA_PREVIEW,
  SEARCH_CRITERIA_VAT_5_5,
} from 'settings/search';
import { TAX_TYPE_MALRAUX } from 'settings/taxes';

import type { LotExport } from 'api/viOffresAPI/apiTypes/LotType';
import type {
  ProgramExport,
  ProgramListType,
  ProgramTypeV2,
} from 'api/viOffresAPI/apiTypes/Program';
import { isProgramListType, isProgramTypeV2 } from 'api/viOffresAPI/apiTypes/Program';
import type { TaxType, TaxesById } from 'api/viOffresAPI/apiTypes/Taxonomies';

import { getLotPrice } from './lots';

type Budget = { min: Record<string, string>; max: Record<string, string> };
function getBudget(lots: LotExport[], programTaxLabels: TaxType[]): { min: number; max: number };
function getBudget(
  budget: Budget,
  attributionList: string | undefined
): { min: number; max: number };
function getBudget(lotsOrBudget: LotExport[] | Budget, taxesOrList?: string | TaxType[]) {
  if (Array.isArray(lotsOrBudget)) {
    return lotsOrBudget.reduce<{ min: number; max: number }>(
      ({ min, max }, lot) => {
        const lotPrice = getLotPrice(lot, taxesOrList as TaxType[]);
        if (Number.isNaN(lotPrice)) {
          return { min, max };
        }
        return {
          min: typeof min !== 'undefined' ? Math.min(min, lotPrice) : lotPrice,
          max: typeof max !== 'undefined' ? Math.max(max, lotPrice) : lotPrice,
        };
      },
      { min: Infinity, max: -Infinity }
    );
  }
  return {
    min:
      typeof taxesOrList === 'string' && taxesOrList in lotsOrBudget.min
        ? Number(lotsOrBudget.min[taxesOrList])
        : null,
    max:
      typeof taxesOrList === 'string' && taxesOrList in lotsOrBudget.max
        ? Number(lotsOrBudget.max[taxesOrList])
        : null,
  };
}

function getKindLabels(lots: LotExport[]): string[];
function getKindLabels(
  kinds: Record<string, string[]>,
  attributionList: string | undefined
): string[];
function getKindLabels(
  lotsOrKinds: LotExport[] | Record<string, string[]>,
  attributionList?: string
) {
  let kinds: string[];
  if (Array.isArray(lotsOrKinds)) {
    kinds = Array.from(new Set(lotsOrKinds.map(lot => lot[LOT_JSON_KIND])));
  } else {
    kinds = attributionList && attributionList in lotsOrKinds ? lotsOrKinds[attributionList] : [];
  }

  return kinds.sort((a, b) => KINDS_ORDER.indexOf(a) - KINDS_ORDER.indexOf(b));
}

function formatProgram(
  program: ProgramExport,
  lots: LotExport[],
  taxById: TaxesById | undefined
): ProgramListType;
function formatProgram(program: ProgramExport, attributionList: string): ProgramListType;
function formatProgram(
  program: ProgramExport,
  lotsOrAttributionList?: LotExport[] | string,
  taxById?: TaxesById
): ProgramListType {
  const [
    nid,
    lat,
    lng,
    name,
    city,
    postalCode,
    department,
    region,
    min,
    max,
    lotNumber,
    kinds,
    kindsDisplayRaw,
    delivery,
    taxes,
    image,
    price,
    profitability,
    photos,
    surface,
    annexes,
    actability,
    others,
    eligibility,
    ref,
    attribution,
    offers,
    housingType,
    cuisineEquipee,
    venteInterne,
    ventePartenaireVi2p,
    cleanCity,
    url3d,
    ,
    promotions,
    marketing,
    reports,
    eligibilityAdb,
  ] = program;
  let budget: { min: number; max: number };
  let kindsDisplay: string[];
  if (Array.isArray(lotsOrAttributionList)) {
    const programLots = lotsOrAttributionList.filter(lot => lot[LOT_JSON_PROGRAM_REF] === ref);
    budget = getBudget(programLots, taxById ? taxes.map(taxTID => taxById[taxTID]) : []);
    kindsDisplay = getKindLabels(programLots);
  } else {
    budget = getBudget({ min, max }, lotsOrAttributionList);
    kindsDisplay = getKindLabels(kindsDisplayRaw, lotsOrAttributionList);
  }

  return {
    nid,
    lat,
    lng,
    name,
    city,
    postalCode,
    department,
    region,
    budget,
    lotNumber: Number(lotNumber),
    kinds,
    kindsDisplay,
    delivery: delivery ? new Date(delivery) : null,
    taxes,
    image: (Array.isArray(image) ? image : [image]).filter(Boolean),
    price: Number(price),
    profitability: Number(profitability),
    photos,
    surface: surface ? { min: Number(surface[0]), max: Number(surface[1]) } : null,
    annexes,
    actability: actability ? new Date(actability) : null,
    others,
    eligibility: eligibility === '1',
    eligibilityAdb: eligibilityAdb === '1',
    ref,
    attribution,
    offers,
    housingType,
    cuisineEquipee,
    venteInterne,
    ventePartenaireVi2p,
    cleanCity,
    url3d,
    promotions,
    marketing: marketing ? new Date(marketing) : null,
    reports: reports === '1',
  };
}

export function formatProgramsSwitchedOn(
  data: ProgramExport[],
  lots: LotExport[],
  taxById: TaxesById | undefined
) {
  return data
    .filter(item => item.length >= PROGRAM_ARRAY_LENGTH)
    .map(program => formatProgram(program, lots, taxById));
}

export function formatProgramsSwitchedOff(
  data: ProgramExport[],
  attributionList: string = window.vinci.REACT_APP_VINCI_DEFAULT_ATTRIBUTION
) {
  return data
    .filter(item => item.length >= PROGRAM_ARRAY_LENGTH)
    .map(program => formatProgram(program, attributionList))
    .filter(
      // Filter out programs that don't have the user's attribution list UNLESS they are in preview
      // (which means they have no lot and no attribution list)
      program =>
        program.attribution.includes(attributionList) ||
        program.others.includes(SEARCH_CRITERIA_PREVIEW)
    );
}

export function programIsPreview(program: ProgramListType | ProgramTypeV2) {
  if (isProgramListType(program)) {
    return program.others.includes(SEARCH_CRITERIA_PREVIEW);
  }
  if (isProgramTypeV2(program)) {
    return program.phaseCommerciale === PROGRAM_PHASE_PREVIEW;
  }
  return false;
}

export function programIsNew(program: ProgramListType | ProgramTypeV2) {
  if (isProgramListType(program)) {
    return program.others.includes(SEARCH_CRITERIA_NEW);
  }
  if (isProgramTypeV2(program)) {
    return program.nouveaute;
  }
  return false;
}

export function programIsSenior(program: ProgramListType | ProgramTypeV2) {
  if (isProgramListType(program)) {
    return program.housingType === PROGRAM_HOUSING_TYPE_SENIOR;
  }
  if (isProgramTypeV2(program)) {
    return program.type === PROGRAM_HOUSING_TYPE_SENIOR;
  }
  return false;
}

export function programIsStudent(program: ProgramListType | ProgramTypeV2) {
  if (isProgramListType(program)) {
    return program.housingType === PROGRAM_HOUSING_TYPE_STUDENT;
  }
  if (isProgramTypeV2(program)) {
    return program.type === PROGRAM_HOUSING_TYPE_STUDENT;
  }
  return false;
}

export function programIsControlledPrice(program: ProgramListType | ProgramTypeV2) {
  if (isProgramListType(program)) {
    return program.others.includes(SEARCH_CRITERIA_CONTROLLED_PRICE);
  }
  if (isProgramTypeV2(program)) {
    return program.informationsFiscalesEtFinancieres.prixMaitrises;
  }
  return false;
}

export function programIsHonoBoost(program: ProgramListType | ProgramTypeV2) {
  if (isProgramListType(program)) {
    return program.others.includes(SEARCH_CRITERIA_HONOBOOST);
  }
  if (isProgramTypeV2(program)) {
    return program.promotions.some(promo => promo.honoboost);
  }
  return false;
}

export function programIsReducedVAT(program: ProgramListType | ProgramTypeV2) {
  if (isProgramListType(program)) {
    return (
      program.others.includes(SEARCH_CRITERIA_VAT_5_5) && !program.taxes.includes(TAX_TYPE_MALRAUX)
    );
  }
  if (isProgramTypeV2(program)) {
    return (
      program.tva_reduite_vi3p &&
      !program.informationsFiscalesEtFinancieres.fiscalites.includes(TAX_TYPE_MALRAUX)
    );
  }
  return false;
}

export function programIsADB(program: ProgramTypeV2 | ProgramListType) {
  if (isProgramTypeV2(program)) {
    return program?.packs?.eligibleAdb;
  }
  if (isProgramListType(program)) {
    return program.eligibilityAdb;
  }
  return false;
}

export function programHasKitchen(program: ProgramTypeV2 | ProgramListType) {
  if (isProgramTypeV2(program)) {
    return program?.packs?.cuisineEquipee;
  }
  if (isProgramListType(program)) {
    return program.cuisineEquipee;
  }
  return false;
}

export function programIsFNO(program: ProgramListType | ProgramTypeV2) {
  if (isProgramListType(program)) {
    return program.ventePartenaireVi2p;
  }
  if (isProgramTypeV2(program)) {
    return program.aidesVente.fraisNotaireOfferts.ventePartenaireVI2P;
  }
  return false;
}

export function programIsVi4You(program: ProgramListType | ProgramTypeV2) {
  if (isProgramTypeV2(program)) {
    return program.offresServices?.includes('VI4YOU');
  }
  if (isProgramListType(program)) {
    return program.offers?.includes('VI4YOU');
  }
  return false;
}

export function programIsFurnishEligible(program: ProgramListType | ProgramTypeV2) {
  if (isProgramListType(program)) {
    return program.others.includes(SEARCH_CRITERIA_FURNISHED);
  }
  if (isProgramTypeV2(program)) {
    return program.packs.eligibleMeuble;
  }
  return false;
}

export function programIsManaged(program: ProgramTypeV2) {
  // if (isProgramListType(program)) {
  //   TODO ProgramListType doesn't hold this information
  // }
  if (isProgramTypeV2(program)) {
    return program.residencesGerees.residenceGeree;
  }
  return false;
}

export function sortLotsRepartition(
  repartition: Record<string, number> | undefined,
  sortArray: string[]
) {
  if (!repartition) {
    return [];
  }
  return Object.entries(repartition)
    .map(([key, value]) => ({
      typology: key,
      nb: value,
    }))
    .sort((a, b) => sortArray.indexOf(a.typology) - sortArray.indexOf(b.typology));
}
