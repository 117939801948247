import React from 'react';
import classnames from 'classnames';

import {
  TAX_LABEL_TYPE_BRS,
  TAX_LABEL_TYPE_MALRAUX,
  TAX_LABEL_TYPE_PINEL_DEROGE,
  TAX_LABEL_TYPE_PINEL_PLUS,
  TAX_LABEL_TYPE_PLS,
  TAX_TYPE_DEMEMBREMENT,
  TAX_TYPE_LMNP,
  TAX_TYPE_PINEL,
} from 'settings/taxes';

import SvgIcon from 'commonUi/SvgIcon/SvgIcon';

import styles from './PastilleFiscality.module.scss';

const TAX_ICON = {
  // [TAX_LABEL_TYPE_NONE] is voluntarily omitted so the "Hors Dispositif" picto is not shown
  [TAX_TYPE_PINEL]: 'icon-fiscality-pinel',
  [TAX_TYPE_LMNP]: 'icon-fiscality-lmnp',
  [TAX_TYPE_DEMEMBREMENT]: 'icon-fiscality-nue-propriete',
  [TAX_LABEL_TYPE_PINEL_PLUS]: 'icon-fiscality-pinel-plus',
  [TAX_LABEL_TYPE_BRS]: 'icon-fiscality-brs',
  [TAX_LABEL_TYPE_PLS]: 'icon-fiscality-pls',
  [TAX_LABEL_TYPE_PINEL_DEROGE]: 'icon-fiscality-pinel-deroge',
  [TAX_LABEL_TYPE_MALRAUX]: 'icon-fiscality-malraux',
};

export interface PastilleFiscalityProps {
  className?: string;
  tax: string | undefined;
}

export default function PastilleFiscality({ className, tax }: PastilleFiscalityProps) {
  if (!tax || !TAX_ICON[tax]) {
    return null;
  }
  return <SvgIcon className={classnames(className, styles.root)} iconId={TAX_ICON[tax]} />;
}
