import { ANNEX_CELLAR_TERM, ANNEX_OUTER_SURFACE_TERM, ANNEX_PARKING_TERM } from 'settings/annexes';

import {
  SEARCH_CRITERIA_CONTROLLED_PRICE,
  SEARCH_CRITERIA_FREE_LOTS,
  SEARCH_CRITERIA_HONOBOOST,
  SEARCH_CRITERIA_NEW,
  SEARCH_CRITERIA_VAT_5_5,
  SEARCH_MAX_PRIZE,
} from 'settings/search';

import type { AnnexTaxonomyTerm } from 'api/viOffresAPI/apiTypes/Taxonomies';
import type { SearchType } from 'modules/HomePage/hooks/useSearch';

export function locationToObject(locations: SearchType['locations'] | undefined) {
  const locationObj: { [x: string]: string }[] | undefined = locations?.map(s => {
    const obj = {};
    s?.split(',')?.forEach(element => {
      const [label, value] = element.split('=');
      obj[label] = value;
    });
    return obj;
  });

  return locationObj;
}

export function buildCriteriasFromSearch(
  filters: Partial<SearchType>,
  annexOptions: AnnexTaxonomyTerm[]
) {
  const filtersMap = {
    actability: 'actabilite',
    budgetMax: 'budget[max]',
    budgetMin: 'budget[min]',
    delivery: 'annees',
    kinds: 'type_lots',
    profitabilityMax: 'rentabilite[max]',
    profitabilityMin: 'rentabilite[min]',
    surfaceMax: 'surface_hab_max',
    surfaceMin: 'surface_hab_min',
    taxes: 'fiscalite',
  };

  const criterias = Object.keys(filters).reduce<Record<string, string | string[] | undefined>>(
    (criterias, key) => {
      switch (key) {
        case 'actability':
        case 'budgetMin':
        case 'delivery':
        case 'kinds':
        case 'profitabilityMax':
        case 'profitabilityMin':
        case 'surfaceMax':
        case 'surfaceMin':
        case 'taxes':
          return {
            ...criterias,
            ...{ [filtersMap[key]]: filters[key] },
          };

        case 'budgetMax':
          if (
            filters?.budgetMax !== undefined &&
            parseInt(filters.budgetMax, 10) !== SEARCH_MAX_PRIZE
          ) {
            return {
              ...criterias,
              [filtersMap[key]]: filters[key],
            };
          }
          return criterias;

        case 'locations': {
          const localityParams = locationToObject(filters.locations)?.reduce<
            Record<'dpt' | 'locality' | 'region', string[]>
          >(
            (acc, loc) => {
              if (loc.type === 'region') {
                return { ...acc, region: [...acc.region, loc.id] };
              }
              if (loc.type === 'department') {
                return { ...acc, dpt: [...acc.dpt, loc.id] };
              }
              return { ...acc, locality: [...acc.locality, loc.value] };
            },
            { dpt: [], locality: [], region: [] }
          );
          return {
            ...criterias,
            dpt: localityParams?.dpt.join(',') ?? '',
            locality: localityParams?.locality.join(',') ?? '',
            region: localityParams?.region.join(',') ?? '',
          };
        }

        case 'others':
          return {
            ...criterias,
            autres_criteres_libre_uniquement: filters[key]?.includes(SEARCH_CRITERIA_FREE_LOTS)
              ? '1'
              : '',
            autres_criteres_nouveaute: filters[key]?.includes(SEARCH_CRITERIA_NEW) ? '1' : '',
            autres_criteres_prix_maitrises: filters[key]?.includes(SEARCH_CRITERIA_CONTROLLED_PRICE)
              ? '1'
              : '',
            autres_criteres_honoboost: filters[key]?.includes(SEARCH_CRITERIA_HONOBOOST) ? '1' : '',
            field_tva_reduite_1: filters[key]?.includes(SEARCH_CRITERIA_VAT_5_5) ? '1' : '',
          };

        case 'annexes': {
          const annexesById =
            annexOptions && Object.fromEntries(annexOptions.map(a => [a.id, a.nom]));
          const filterTerms = filters[key]?.map(tid => annexesById[tid]);
          return {
            ...criterias,
            annexes_cave: filterTerms?.includes(ANNEX_CELLAR_TERM) ? '1' : '',
            annexes_parking: filterTerms?.includes(ANNEX_PARKING_TERM) ? '1' : '',
            annexes_surface_exterieur: filterTerms?.includes(ANNEX_OUTER_SURFACE_TERM) ? '1' : '',
          };
        }

        default:
          return criterias;
      }
    },
    { medias: 'Site VI2P', phases_commerciales: ['Commercialisation'] }
  );

  return Object.fromEntries(
    Object.entries(criterias).filter(
      ([, value]) => !!value && (!Array.isArray(value) || value.length > 0)
    )
  ) as Record<string, string | string[]>;
}
