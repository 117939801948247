import React, { useMemo } from 'react';
import type { ComponentProps } from 'react';
import { Text, View } from '@react-pdf/renderer';

import { LABEL_NB_AVAILABLE_LOT, LABEL_NB_AVAILABLE_LOTS } from 'settings/labels';
import { PROGRAM_KINDS_ORDER } from 'settings/programs';
import { TOKEN_NB } from 'settings/token';

import { replaceTokens } from 'services/formatter';
import { getLotsUniqueTaxType } from 'services/lots';
import {
  getPDFLotsGridHeadings,
  getPDFLotsGridHeadingsWeight,
  getPDFLotsGridHeadingsWidth,
} from 'services/pdf';
import { sortLotsRepartition } from 'services/programs';

import type { ProgramTypeV2 } from 'api/viOffresAPI/apiTypes/Program';
import type { LotJson } from 'api/viOffresAPI/apiTypes/LotType';
import type { TaxesById } from 'api/viOffresAPI/apiTypes/Taxonomies';

import PdfLotCell from '../../Common/PdfLotCell/PdfLotCell';
import PdfSvgPicto from '../../Common/PdfSvgPicto/PdfSvgPicto';

import styles from './ProgramPdfPricesGridStyles';

export interface ProgramPdfLotsTableProps {
  program: ProgramTypeV2;
  lots: LotJson[];
  statuses: ComponentProps<typeof PdfLotCell>['statuses'];
  taxById: TaxesById;
}

export default function ProgramPdfPricesGrid({
  program,
  lots,
  statuses,
  taxById,
}: ProgramPdfLotsTableProps) {
  const typology =
    typeof program?.repartitionsLots === 'string'
      ? (JSON.parse(program.repartitionsLots) as Record<string, number>)
      : undefined;

  const tax = getLotsUniqueTaxType(lots, taxById);

  const [headings, headingsWidth] = useMemo(() => {
    const headings = getPDFLotsGridHeadings(tax, true);
    return [headings, getPDFLotsGridHeadingsWidth(headings, getPDFLotsGridHeadingsWeight(tax))];
  }, [tax]);

  const sortedTypologies = sortLotsRepartition(typology, PROGRAM_KINDS_ORDER);

  return (
    <View style={styles.block}>
      <View style={styles.blockTop}>
        <Text style={styles.availableLots}>
          {replaceTokens(lots.length === 1 ? LABEL_NB_AVAILABLE_LOT : LABEL_NB_AVAILABLE_LOTS, {
            [TOKEN_NB]: lots.length,
          })}
        </Text>
        <View style={styles.blockTopRight}>
          {lots.length > 0 && typology && (
            <View style={styles.typologies}>
              {sortedTypologies.map((typology, idx) => (
                <Text key={typology.typology}>
                  {typology.typology} ({typology.nb})
                  {idx < sortedTypologies.length - 1 ? ' | ' : ''}
                </Text>
              ))}
            </View>
          )}
        </View>
      </View>
      <View>
        <View style={styles.gridHeadings} wrap={false} fixed>
          {headings?.map(col => (
            <View
              key={col.id}
              style={[styles.gridHeadingsCell, { width: `${headingsWidth[col.id]}%` }]}
            >
              <Text>{col.title}</Text>
              <View style={styles.sortPicto}>
                <PdfSvgPicto picto="sort" />
              </View>
              <View style={styles.subText}>{col.sub && <Text>{col.sub}</Text>}</View>
            </View>
          ))}
        </View>
        <View style={styles.grid}>
          {lots.map(lot => (
            <View key={lot.nid} style={styles.gridRow} wrap={false}>
              {headings.map(col => (
                <View
                  key={`${lot.nid}-${col.id}`}
                  style={[styles.gridCell, { width: `${headingsWidth[col.id]}%` }]}
                >
                  <PdfLotCell col={col} lot={lot} statuses={statuses} taxById={taxById} />
                </View>
              ))}
            </View>
          ))}
        </View>
      </View>
    </View>
  );
}
