import type { ComponentProps } from 'react';
import React, { useContext, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';
import classnames from 'classnames';

import { TAX_TYPE_PINEL, TAX_TYPE_PINEL_DEROGE, TAX_TYPE_PINEL_PLUS } from 'settings/taxes';
import { TMS_TIMEOUT } from 'settings/tms';
import { LABEL_SEARCH_NO_RESULTS, LABEL_SEARCH_NO_RESULTS_NEAR_PROGRAMS } from 'settings/labels';
import { SEARCH_CRITERIA_FURNISHED } from 'settings/search';

import { LotJson } from 'api/viOffresAPI/apiTypes/LotType';
import { ProgramListType } from 'api/viOffresAPI/apiTypes/Program';
import { SelectedLotType } from 'types/lots';

import TaxonomiesContext from 'modules/App/Contexts/TaxonomiesContext';

import { getHeadingsWidth, getLotHeadings } from 'services/lots';
import { getColumnsWidths } from 'services/ui';
import { modifyQuery } from 'services/url';

import SpinLoader from 'commonUi/SpinLoader/SpinLoader';
import TagCommanderEvent from 'modules/App/TagCommander/TagCommanderEvent';

import { useSearch } from '../../hooks/useSearch';

import LotTableDesktop from './LotTableDesktop';

import styles from './LotsList.module.scss';

interface LotsListDesktopProps
  extends Pick<ComponentProps<typeof LotTableDesktop>, 'reorder' | 'sortBy' | 'sortOrder'> {
  isLoading?: boolean;
  isPanelExpanded: boolean;
  handleTogglePanel: () => void;
  lots: LotJson[];
  programs?: ProgramListType[];
  pageTemplate?: string;
  pageTemplateRef?: string;
  selectedLots: SelectedLotType[];
  setSelectedLots: (selectedLots: SelectedLotType[]) => void;
}

export default function LotsListDesktop({
  handleTogglePanel,
  lots,
  isLoading = false,
  isPanelExpanded,
  programs = [],
  pageTemplate = undefined,
  pageTemplateRef = undefined,
  reorder,
  selectedLots,
  setSelectedLots,
  sortBy,
  sortOrder,
}: LotsListDesktopProps) {
  const history = useHistory();
  const { taxesById, isTaxesLoading } = useContext(TaxonomiesContext);
  let programsRef: { programRef: string }[] = [];
  if (programs?.length > 0) {
    programsRef = programs.map(program => ({ programRef: program.ref }));
  } else if (lots?.length > 0) {
    programsRef = lots?.map(lot => ({ programRef: lot.ref }));
  }

  const searchFilters = useSearch();

  const [headings, headingsWidths] = useMemo(() => {
    const taxeFormatted =
      searchFilters.taxes && searchFilters.taxes?.length === 1
        ? taxesById?.[searchFilters.taxes?.[0]]
        : undefined;
    const isPinel =
      taxesById &&
      searchFilters.taxes.every(taxId =>
        [TAX_TYPE_PINEL, TAX_TYPE_PINEL_DEROGE, TAX_TYPE_PINEL_PLUS].includes(taxesById[taxId])
      );
    const headings = getLotHeadings(
      !isPanelExpanded,
      isPinel && searchFilters.taxes.length > 1 ? TAX_TYPE_PINEL : taxeFormatted,
      true,
      false,
      'desktop',
      searchFilters.others.includes(SEARCH_CRITERIA_FURNISHED)
    );
    return [
      headings,
      getColumnsWidths(headings, getHeadingsWidth(!isPanelExpanded, taxeFormatted)),
    ];
  }, [searchFilters.others, searchFilters.taxes, taxesById, isPanelExpanded]);

  const handleOnChangeCheckbox = (programRef: string, lotNumber: string) => {
    if (
      selectedLots.find(
        selectedLot => selectedLot.programRef === programRef && selectedLot.lotNumber === lotNumber
      )
    ) {
      setSelectedLots(
        selectedLots.filter(
          selectedLot =>
            !(selectedLot.programRef === programRef && selectedLot.lotNumber === lotNumber)
        )
      );
      return;
    }
    setSelectedLots([...selectedLots, { programRef, lotNumber }]);
  };

  const displayAnnex = !!searchFilters.nearProgram && searchFilters.locations.length === 1;

  const location = useLocation();
  const params = qs.parse(location.search);

  useEffect(() => {
    if (params?.from === 'display_lots_btn') {
      setTimeout(() => {
        history.replace(modifyQuery({}, ['from']));
      }, TMS_TIMEOUT);
    }
  });

  if (isLoading || isTaxesLoading) {
    return (
      <div className={styles.loading}>
        <SpinLoader width={100} height={100} />
      </div>
    );
  }

  return (
    <div className={classnames(styles.lotsList, { [styles.isExpanded]: isPanelExpanded })}>
      {(!programsRef?.length || displayAnnex) && (
        <div className={styles.noResults}>
          <span>{LABEL_SEARCH_NO_RESULTS}</span>
          {displayAnnex && !!programsRef?.length && (
            <span>{LABEL_SEARCH_NO_RESULTS_NEAR_PROGRAMS}</span>
          )}
        </div>
      )}
      <LotTableDesktop
        lots={lots}
        programsRef={programsRef}
        headings={headings}
        headingsWidth={headingsWidths}
        handleOnChangeCheckbox={handleOnChangeCheckbox}
        isPanelExpanded={isPanelExpanded}
        handleTogglePanel={handleTogglePanel}
        reorder={reorder}
        selectedLots={selectedLots}
        sortBy={sortBy}
        sortOrder={sortOrder}
        pageTemplate={pageTemplate}
        pageTemplateRef={pageTemplateRef}
      />

      <TagCommanderEvent
        isActive={!!params?.from}
        navigation_pagename="resultats_programmes_afficher_lots"
        navigation_template="recherche"
        useEffectDeps={['navigation_pagename', 'navigation_template']}
      />
    </div>
  );
}
