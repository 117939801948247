export const TAX_TYPE_PINEL = 'Pinel';
export const TAX_TYPE_NONE = 'Hors dispositif';
export const TAX_TYPE_DEMEMBREMENT = 'Démembrement';
export const TAX_TYPE_LMNP = 'LMNP';
export const TAX_TYPE_DEFICIT = 'Déficit Foncier';
export const TAX_TYPE_MALRAUX = 'Malraux';
export const TAX_TYPE_PINEL_PLUS = 'Pinel+';
export const TAX_TYPE_BRS = 'Bail Réel Solidaire';
export const TAX_TYPE_PLS = 'Prêt Locatif Social';
export const TAX_TYPE_PINEL_DEROGE = 'Pinel Deroge';

export const TAX_LABEL_TYPE_PINEL = 'Pinel';
export const TAX_LABEL_TYPE_NONE = 'Hors dispositif';
export const TAX_LABEL_TYPE_DEMEMBREMENT = 'Nue Propriété';
export const TAX_LABEL_TYPE_LMNP = 'LMNP';
export const TAX_LABEL_TYPE_DEFICIT = 'Déficit Foncier';
export const TAX_LABEL_TYPE_MALRAUX = 'Malraux';
export const TAX_LABEL_TYPE_PINEL_PLUS = 'Pinel+';
export const TAX_LABEL_TYPE_BRS = 'Bail Réel Solidaire';
export const TAX_LABEL_TYPE_PLS = 'Prêt Locatif Social';
export const TAX_LABEL_TYPE_PINEL_DEROGE = 'Pinel Deroge';
