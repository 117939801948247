import React, { useContext, useMemo, useState } from 'react';

import {
  LABEL_SEARCH_NO_RESULTS_MOBILE,
  LABEL_SEARCH_NO_RESULTS_NEAR_PROGRAMS,
} from 'settings/labels';
import { LOTS_PER_PAGE_MOBILE } from 'settings/lots';
import { SEARCH_CRITERIA_FURNISHED } from 'settings/search';
import { TAX_TYPE_PINEL, TAX_TYPE_PINEL_DEROGE, TAX_TYPE_PINEL_PLUS } from 'settings/taxes';

import type { HeadingType, LotJson } from 'api/viOffresAPI/apiTypes/LotType';

import TaxonomiesContext from 'modules/App/Contexts/TaxonomiesContext';

import { getLotHeadings } from 'services/lots';

import { useInfinitePagerWithTyped } from 'hooks/useInfinitePager';
import { useSearch } from 'modules/HomePage/hooks/useSearch';

import SpinLoader from 'commonUi/SpinLoader/SpinLoader';

import LotTableMobile from './LotTableMobile';

import styles from './LotsList.module.scss';

interface LotsListMobileProps {
  displayAllHeader: boolean;
  isLoading?: boolean;
  reduceMobilePanel?: () => void;
  pageTemplate?: string;
  pageTemplateRef?: string;
  sortedLots: LotJson[];
}

export default function LotsListMobile({
  displayAllHeader,
  isLoading = false,
  reduceMobilePanel = undefined,
  pageTemplate = undefined,
  pageTemplateRef = undefined,
  sortedLots,
}: LotsListMobileProps) {
  const { taxesById, isTaxesLoading } = useContext(TaxonomiesContext);
  const [lotsPage, setLotsPage] = useState(0);

  const searchFilters = useSearch();
  const taxeFormatted =
    searchFilters.taxes && searchFilters.taxes?.length === 1
      ? taxesById?.[searchFilters.taxes?.[0]]
      : undefined;

  const { paginatedItems: paginatedLots, hasMoreItems: hasMoreLots } = useInfinitePagerWithTyped<
    LotJson
  >(lotsPage, LOTS_PER_PAGE_MOBILE, sortedLots);

  const headings = useMemo(() => {
    const isPinel =
      taxesById &&
      searchFilters.taxes.every(taxId =>
        [TAX_TYPE_PINEL, TAX_TYPE_PINEL_DEROGE, TAX_TYPE_PINEL_PLUS].includes(taxesById[taxId])
      );
    return getLotHeadings(
      !displayAllHeader,
      isPinel && searchFilters.taxes.length > 1 ? TAX_TYPE_PINEL : taxeFormatted,
      true,
      false,
      'mobile',
      searchFilters.others.includes(SEARCH_CRITERIA_FURNISHED)
    ) as HeadingType[];
  }, [searchFilters.others, searchFilters.taxes, taxesById, displayAllHeader]);

  if (isLoading || isTaxesLoading) {
    return (
      <div className={styles.loading}>
        <SpinLoader width={100} height={100} />
      </div>
    );
  }

  function getActiveLots() {
    if (searchFilters.programRef) {
      const activeLots = paginatedLots.filter(lot => lot.ref === searchFilters.programRef);
      return activeLots.length
        ? activeLots
        : sortedLots?.filter(lot => lot.ref === searchFilters.programRef);
    }
    return [];
  }

  const displayAnnex = !!searchFilters.nearProgram && searchFilters.locations.length === 1;

  return (
    <div className={styles.lotsList}>
      <div className={styles.lotsList}>
        {(!paginatedLots?.length || displayAnnex) && (
          <div className={styles.noResults}>
            <span>{LABEL_SEARCH_NO_RESULTS_MOBILE}</span>
            {displayAnnex && !!paginatedLots?.length && (
              <span>{LABEL_SEARCH_NO_RESULTS_NEAR_PROGRAMS}</span>
            )}
          </div>
        )}
        <LotTableMobile
          hasMore={hasMoreLots}
          loadMore={() => setLotsPage(prev => prev + 1)}
          activeLots={getActiveLots()}
          activeProgramRef={searchFilters.programRef}
          currentPage={lotsPage}
          headings={headings}
          lots={paginatedLots}
          reduceMobilePanel={reduceMobilePanel}
          pageTemplate={pageTemplate}
          pageTemplateRef={pageTemplateRef}
        />
      </div>
    </div>
  );
}
