import React from 'react';
import type { ComponentProps } from 'react';
import classnames from 'classnames';

import SpinLoader from 'commonUi/SpinLoader/SpinLoader';
import { ActivityDetails } from 'commonUi/ListLotV2/ActivityRow/ActivityDetails/ActivityDetails';
import { LotDetailLayoutDesktop } from 'commonUi/ListLotV2/LotDetailLayout/LotDetailLayoutDesktop';
import LotDetailsImage from '../../commonUi/ListLotV2/LotDetails/LotDetailsImage';

import styles from './ActivityDesktopDetailsCpn.module.scss';

export type ActivityDesktopDetailsCpnType = {
  index: number;
  activity: ComponentProps<typeof ActivityDetails>['activity'];
  lot: ComponentProps<typeof ActivityDetails>['lotFromApi'];
  program: ComponentProps<typeof ActivityDetails>['programFromApi'];
  isLoading?: boolean;
  isOpen?: boolean;
};

export function ActivityDesktopDetailsCpn({
  activity,
  index,
  lot,
  program,
  isLoading = false,
  isOpen = false,
}: ActivityDesktopDetailsCpnType) {
  if (!isOpen) {
    return null;
  }
  if (isLoading) {
    return (
      <div className={classnames(styles.loading, { [styles.odd]: index % 2 })}>
        <SpinLoader width={100} height={100} />
      </div>
    );
  }

  return (
    <LotDetailLayoutDesktop
      isOdd={index % 2 !== 0}
      renderDetails={() => (
        <ActivityDetails
          activity={activity}
          lotFromApi={lot}
          programFromApi={program}
          isOdd={index % 2 !== 0}
        />
      )}
      renderImage={() => <LotDetailsImage lotFromApi={lot} programFromApi={program} />}
    />
  );
}
