import React from 'react';

import { taxMapping } from 'services/taxes';
import { LABEL_LOT_NUMBER } from 'settings';

import type { LotTypeV2 } from 'api/viOffresAPI/apiTypes/LotType';
import type { ProgramTypeV2 } from 'api/viOffresAPI/apiTypes/Program';

import PastilleFiscality from 'commonUi/Pastille/PastilleFiscality';

import styles from './LotCardSimple.module.scss';

interface LotCardSimpleProps {
  lot: Pick<LotTypeV2, 'reference'>;
  program: ProgramTypeV2;
}

export function LotCardSimple({ lot, program }: LotCardSimpleProps) {
  const currentFiscality = taxMapping.get(program.informationsFiscalesEtFinancieres.fiscalites[0]);

  return (
    <div className={styles.root}>
      <div>{currentFiscality && <PastilleFiscality tax={currentFiscality} />}</div>
      <a
        className={styles.programLink}
        href={`/programme/page/${program.referenceProgramme}/prix`}
        rel="noreferrer"
        target="_blank"
      >
        <div className={styles.name}>{program.nomCommercial}</div>
        <div className={styles.location}>
          {`${program.localisation.ville} (${program.localisation.codePostal})`}
        </div>
      </a>
      <div>
        <a
          className={styles.lotLink}
          href={`/programme/page/${program.referenceProgramme}/prix?lotNumber=${lot.reference}&programRef=${program.referenceProgramme}`}
          rel="noreferrer"
          target="_blank"
        >
          {LABEL_LOT_NUMBER} {lot.reference}
        </a>
      </div>
    </div>
  );
}
